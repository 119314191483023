import gql from 'graphql-tag'
import {withFormik} from 'formik'
import {compose, graphql} from 'react-apollo'
import {connect} from 'react-redux'
import Patient from '../components/Patient'
import userStore from '../mobx/user'

export default compose(
  connect(),
  graphql(
    gql`
      query Patient($id: Int!, $practiceId: ID!) {
        patient(patientId: $id) {
          id
          status
          firstName
          lastName
          sticker {
            id
            url
          }
          practitioner {
            id
            name
          }
          hospital {
            id
            name
          }
          referring {
            id
            name
            provider
            specialty
          }
          discharge {
            id
            notes
            practitioner {
              id
              name
            }
            images {
              id
              url
            }
          }
          consultations(practiceId: $practiceId) {
            practitioner {
              id
              name
            }
            patient {
              id
              firstName
            }
            sticker {
              id
              url
            }
            id
            date
            dateTime
            hospital {
              id
              name
            }
            length {
              minutes
              hours
            }
            billingItems {
              id
              quantity
              status
              mbsItem {
                ItemNum
                Description
              }
            }
          }
          billingHistory(practiceId: $practiceId) {
            id
            billingId
            billingItem {
              id
              mbsItem {
                ItemNum
              }
            }
            user {
              id
              name
            }
            createdAt
            from
            to
          }
        }
      }
    `,
    {
      options: ({id}) => ({
        variables: {id: Number(id), practiceId: userStore.practiceId},
        fetchPolicy: 'no-cache',
      }),
      props: ({data: {patient, loading, error, refetch} = {}}) => ({
        error,
        refetch,
        patient,
        loading,
      }),
    }
  ),
  graphql(
    gql`
      mutation updatePatient(
        $patientData: PatientUpdateInput!
        $patientId: Int!
      ) {
        updatePatient(patientId: $patientId, patientData: $patientData) {
          id
        }
      }
    `,
    {
      name: 'updatePatient',
      options: {
        // refetchQueries: ['Patient'],
        // awaitRefetchQueries: true,
      },
    }
  ),
  graphql(
    gql`
      mutation payBillingItems($billingItems: [Int!]!, $patientId: Int!) {
        payBillingItems(patientId: $patientId, billingItems: $billingItems)
      }
    `,
    {
      name: 'payBillingItems',
      options: {
        // refetchQueries: ['Patient'],
        // awaitRefetchQueries: true,
      },
    }
  ),
  graphql(
    gql`
      mutation closePatient($patientId: Int!, $practiceId: Int!) {
        closePatient(patientId: $patientId, practiceId: $practiceId)
      }
    `,
    {
      name: 'closePatient',
      options: {
        // refetchQueries: ['Patient'],
        // awaitRefetchQueries: true,
      },
    }
  )
)(Patient)
