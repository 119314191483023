import {graphql, compose} from 'react-apollo'
import Admins from '../components/Admins'
import {connect} from 'react-redux'
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'
import UserStore from '../mobx/user'

export default compose(
  connect(),
  graphql(
    gql`
      query Admins($practiceId: ID!) {
        me {
          id
          practice(practiceId: $practiceId) {
            id
            name
            admins {
              id
              name
              email
              isActive(practiceId: $practiceId)
              emailConfirmed
              createdAt
            }
          }
        }
      }
    `,
    {
      options: () => ({
        variables: {practiceId: UserStore.practiceId},
      }),
      props: ({loading, data: {me, error, refetch} = {}}) => ({
        me,
        loading,
        error,
        refetch,
      }),
    }
  ),
  withApollo
)(Admins)
