import {graphql, compose} from 'react-apollo'
import ConsultationsDelete from '../components/ConsultationsDelete'
import {connect} from 'react-redux'
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'
import {withState} from 'recompose'
import {observer, inject} from 'mobx-react'
import userStore from '../mobx/user'

var endingDate = new Date()
endingDate.setHours(0)
endingDate.setMonth(endingDate.getMonth() - 12)

const filtyBoy = {
  end: endingDate,
  doctorId: null,
  patientId: null,
}

export default compose(
  connect(),
  withState('first', 'setFirst', 30),
  withState('after', 'setAfter', 0),
  withState('filter', 'setFilter', filtyBoy),
  withState('sort', 'setSort', {createdAt: true}),
  graphql(
    gql`
      query Doctors($practiceId: ID!) {
        me {
          practice(practiceId: $practiceId) {
            id
            doctors {
              id
              name
            }
            patients {
              id
              firstName
              lastName
              dateOfBirth
            }
          }
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
          practiceId: userStore.practiceId,
        },
      }),
      props: ({error, networkStatus, data: {me = {}, loading}}) => {
        return {
          loading,
          doctors:
            me && me.practice && me.practice.doctors ? me.practice.doctors : [],
          patients:
            me && me.practice && me.practice.patients
              ? me.practice.patients
              : [],
        }
      },
    }
  ),
  graphql(
    gql`
      query unpaidPractitioners($practiceId: ID!) {
        unpaidPractitioners(practiceId: $practiceId) {
          id
          name
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
          practiceId: userStore.practiceId,
        },
      }),
      props: ({
        // error,
        // networkStatus,
        data: {unpaidPractitioners = [], loading},
      }) => {
        return {
          unpaidPractitionersLoading: loading,
          unpaidPractitioners,
        }
      },
    }
  ),
  graphql(
    gql`
      mutation deleteConsultations(
        $practiceId: ID!
        $end: String!
        $consultations: [Float!]
      ) {
        deleteConsultations(
          practiceId: $practiceId
          end: $end
          consultations: $consultations
        )
      }
    `,
    {
      name: 'deleteConsultations',
      options: {
        refetchQueries: ['primeDeleteConsultations'],
      },
    }
  ),
  graphql(
    gql`
      query primeDeleteConsultations(
        $practiceId: ID!
        $filter: ConsultationExportInput
      ) {
        primeDeleteConsultations(filter: $filter, practiceId: $practiceId) {
          id
          length {
            seconds
            minutes
            hours
          }
          patient {
            id
            firstName
            lastName
            hospital {
              id
              name
            }
          }
          hospital {
            id
            name
          }
          practitioner {
            id
            name
            specialty {
              id
              name
            }
          }
          createdAt
          dateTime
          billingItems {
            quantity
          }
        }
      }
    `,
    {
      options: ({first, after, filter, sort}) => ({
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
          first,
          after,
          filter,
          sort,
          practiceId: userStore.practiceId,
        },
      }),
      props: ({
        error,
        networkStatus,
        data: {primeDeleteConsultations, fetchMore, refetch, loading} = {},
      }) => ({
        primeDeleteConsultations,
        loading,
        error,
        fetchMore,
        refetch,
        networkStatus,
      }),
    }
  ),
  withApollo
)(inject('userStore')(observer(ConsultationsDelete)))
