import gql from 'graphql-tag'
import {compose, graphql} from 'react-apollo'
import {connect} from 'react-redux'
import AdminForm from '../components/AdminForm'
import {withFormik} from 'formik'
import {push} from 'connected-react-router'
import {withState} from 'recompose'
import {toast} from 'react-toastify'
import UserStore from '../mobx/user'
import {observer} from 'mobx-react'
import * as Yup from 'yup'

const EditAdminValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password: Yup.string().min(6, 'Length >= 6 characters'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
})

export default compose(
  connect(),
  graphql(
    gql`
      query Admin($practiceId: ID!, $id: ID!) {
        me {
          id
          practice(practiceId: $practiceId) {
            id
            owner {
              id
            }
            admin(id: $id) {
              id
              name
              email
              isActive(practiceId: $practiceId)
            }
          }
        }
      }
    `,
    {
      options: ({id}) => ({
        variables: {id, practiceId: UserStore.practiceId},
      }),
      props: ({data: {me, loading, error, refetch} = {}}) => ({
        error,
        refetch,
        me,
        loading,
      }),
    }
  ),
  graphql(
    gql`
      mutation UpdateAdmin(
        $id: ID!
        $practiceId: ID!
        $input: AdminUpdateInput!
      ) {
        updateAdmin(id: $id, practiceId: $practiceId, input: $input) {
          id
          name
          email
        }
      }
    `,
    {
      name: 'updateAdmin',
      options: {
        refetchQueries: ['Admins', 'Admin'],
      },
    }
  ),
  graphql(
    gql`
      mutation ToggleActive($practiceId: ID!, $userId: ID!) {
        toggleActive(practiceId: $practiceId, userId: $userId)
      }
    `,
    {
      name: 'toggleActive',
      options: {
        refetchQueries: ['Admins', 'Admin'],
      },
    }
  ),
  withFormik({
    enableReinitialize: true,
    validationSchema: EditAdminValidationSchema,
    mapPropsToValues: ({me}) => {
      if (!me) {
        return {}
      }

      const {name, email, isActive} = me.practice.admin

      return {
        name,
        email,
        isActive,
        password: '',
      }
    },
    handleSubmit: async (
      input,
      {props: {id, updateAdmin, dispatch}, setSubmitting}
    ) => {
      console.log('Admin handleSubmit')
      console.log('input', input)
      console.log('id', id)
      try {
        let result = await updateAdmin({
          variables: {
            id,
            practiceId: Number(UserStore.practiceId),
            input,
          },
        })
        console.log('updateAdmin result', result)
        toast.success('Saved Admin!')
        dispatch(push(`/admins`))
      } catch (error) {
        console.log('error', error)
        toast.error('Failed to update admin, please try again.', {})
      } finally {
        setSubmitting(false)
      }
    },
  }),
  withState('passwordRequired', 'setPasswordRequired', false),
  withState('title', 'setTitle', 'Edit Admin')
)(observer(AdminForm))
