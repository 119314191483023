import {push} from 'connected-react-router'
import {NavLink} from 'react-router-dom'
import React, {Component} from 'react'

import {
  Button,
  Container,
  Divider,
  Image,
  Input,
  Message,
  Segment,
  Dimmer,
  Loader,
  Form,
  Header,
} from 'semantic-ui-react'

import logo from '../assets/weblogo.png'
import {toast} from 'react-toastify'
import {parseError} from '../utils'
// import '../styles/Login.scss'

export default class ActivateAccount extends Component {
  state = {
    submitting: false,
    error: '',
  }

  async componentDidMount() {
    this.activateAccount()
  }

  activateAccount = async () => {
    const {activateAccount, hash} = this.props

    this.setState({submitting: true})

    try {
      let result = await activateAccount({hash})
      this.setState({result})
    } catch (err) {
      this.setState({error: parseError(err)})
    }

    this.setState({submitting: false})
  }

  render() {
    const {submitting, result, error} = this.state
    return (
      <Container textAlign="center">
        <Segment padded="very">
          {submitting === true ? (
            <Dimmer active inverted>
              <Loader size="medium">Loading</Loader>
            </Dimmer>
          ) : null}

          {!!result && !error && <Header as="h2">Account Activated</Header>}

          {!!error && <Header as="h2">{error}</Header>}
        </Segment>
      </Container>
    )
  }
}
