import {graphql, compose} from 'react-apollo'
import Doctors from '../components/Doctors'
import {connect} from 'react-redux'
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'
import userStore from '../mobx/user'

export default compose(
  connect(),
  graphql(
    gql`
      query Doctors($practiceId: ID!) {
        me {
          id
          practice(practiceId: $practiceId) {
            id
            name
            doctors {
              id
              ahpra
              name
              isActive(practiceId: $practiceId)
              emailConfirmed
              specialty {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      options: () => ({
        variables: {
          practiceId: userStore.practiceId,
        },
      }),
      props: ({loading, data: {me} = {}}) => ({
        me,
        loading,
      }),
    }
  ),
  withApollo
)(Doctors)
