import React, {Component, useContext} from 'react'
import {NavLink} from 'react-router-dom'
import {toast} from 'react-toastify'
import {
  Container,
  Icon,
  Image,
  Menu,
  Responsive,
  Sidebar,
  Dropdown,
} from 'semantic-ui-react'
import client from '../apollo'
import logo from '../assets/weblogo.png'
import UserStore from '../mobx/user'
import {isPracticeOwner} from '../utils'

const Home = () => (
  <Menu.Item style={{paddingTop: 0, paddingBottom: 0}}>
    <NavLink exact to="/">
      <Image size="mini" src={logo} style={{width: 'auto', height: 40}} />
    </NavLink>
  </Menu.Item>
)

const NavBarMobile = ({
  children,
  leftItems,
  onPusherClick,
  onToggle,
  rightItems,
  visible,
}) => (
  <Sidebar.Pushable>
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      // inverted
      items={leftItems}
      vertical
      visible={visible}
    />
    <Sidebar.Pusher
      dimmed={visible}
      onClick={onPusherClick}
      style={{minHeight: '100vh'}}
    >
      <Menu fixed="top" inverted>
        <Home />
        <Menu.Item onClick={onToggle}>
          <Icon name="sidebar" />
        </Menu.Item>
        <Menu.Menu position="right">
          {rightItems.map(item => (
            <Menu.Item {...item} />
          ))}
        </Menu.Menu>
      </Menu>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>
)

const NavBarDesktop = ({leftItems, rightItems}) => (
  <Menu fixed="top" className="no-print">
    <Home />
    {leftItems.map(item => (
      <Menu.Item {...item} />
    ))}
    <Menu.Menu position="right">
      {rightItems.map(item => (
        <Menu.Item key={item.key}>{item}</Menu.Item>
      ))}
    </Menu.Menu>
  </Menu>
)

const NavBarChildren = ({children}) => (
  <Container style={{paddingTop: 45}} fluid>
    {children}
  </Container>
)

class NavBar extends Component {
  state = {
    visible: false,
  }

  handlePusher = () => {
    const {visible} = this.state

    if (visible) this.setState({visible: false})
  }

  handleToggle = () => this.setState({visible: !this.state.visible})

  render() {
    const {children, leftItems, rightItems} = this.props
    const {visible} = this.state

    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <NavBarMobile
            leftItems={leftItems}
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            rightItems={rightItems}
            visible={visible}
          >
            <NavBarChildren>{children}</NavBarChildren>
          </NavBarMobile>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <NavBarDesktop leftItems={leftItems} rightItems={rightItems} />
          <NavBarChildren>{children}</NavBarChildren>
        </Responsive>
      </div>
    )
  }
}

export default ({me, children}) => {
  let selPractice = false

  if (!me || !me.practices) {
    return <div />
  }

  if (!UserStore.practiceId && me.practices.length > 0) {
    console.log(`${me.practices[0].id}`)
    UserStore.setPracticeId(`${me.practices[0].id}`)
  }

  selPractice = me.practices.find(practice => {
    return practice.id == UserStore.practiceId
  })

  if (!selPractice) {
    UserStore.setPracticeId('')
  }

  if (!selPractice && me.practices.length > 0) {
    selPractice = me.practices[0]
  }

  let leftItems = []
  if (me) {
    leftItems = [
      {
        as: NavLink,
        content: 'Patients',
        key: 'patients',
        to: '/patients',
        disabled: selPractice ? false : true,
      },
      {
        as: NavLink,
        content: 'Consultations',
        key: 'consultations',
        to: '/consultations',
        disabled: selPractice ? false : true,
      },
    ]
    if (
      me.userType === 'OFFICE_ADMIN' ||
      isPracticeOwner(UserStore.practiceId, me)
    ) {
      leftItems.push({
        as: NavLink,
        content: 'Doctors',
        key: 'doctors',
        to: '/doctors',
        disabled: selPractice ? false : true,
      })
      leftItems.push({
        as: NavLink,
        content: 'Admins',
        key: 'admins',
        to: '/admins',
        disabled: selPractice ? false : true,
      })
    }
  }

  // if (isPracticeOwner(UserStore.practiceId, me)) {
  //   leftItems.push({
  //     as: NavLink,
  //     content: 'Practice',
  //     key: 'practice',
  //     to: '/practice',
  //   })
  // }

  const rightItems = [
    me ? (
      <Dropdown
        item
        simple
        text={selPractice ? selPractice.name : 'No Practices'}
        disabled={selPractice ? false : true}
        direction="right"
        value={UserStore.practiceId}
        onChange={(event, {value}) => {
          console.log(value)
          UserStore.setPracticeId(value)
        }}
        options={me.practices.map(practice => {
          return {
            key: practice.id,
            text: practice.name,
            value: practice.id,
          }
        })}
      />
    ) : null,
    me ? (
      <NavLink key="editMe" to={`/admins/${me ? me.id : ''}`}>
        {me ? me.name : ''}
      </NavLink>
    ) : null,

    me ? (
      <NavLink
        key="logout"
        to={'/'}
        onClick={() => {
          UserStore.setToken('')
          localStorage.removeItem('token')
          client.resetStore()
          toast.info('You are now logged out.', {})
        }}
      >
        Logout
      </NavLink>
    ) : (
      <NavLink key="login" to={'/login'}>
        Login
      </NavLink>
    ),
  ].filter(Boolean)

  return (
    <NavBar leftItems={leftItems} rightItems={rightItems}>
      {children}
    </NavBar>
  )
}
