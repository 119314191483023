import React from 'react'
import ReactDOM from 'react-dom'

import 'semantic-ui-css/semantic.min.css'
import 'react-table/react-table.css'
import './index.scss'

import App from './containers/App'
import {ApolloProvider} from 'react-apollo'
import client from './apollo'
import {createBrowserHistory} from 'history'
import {Provider} from 'react-redux'
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import rootReducer from './reducers'
import * as serviceWorker from './serviceWorker'
import userStore from './mobx/user'
import {observer} from 'mobx-react'

import {Provider as MobxProvider} from 'mobx-react'

// import branch from 'branch-sdk'

const history = createBrowserHistory()

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer(history),
  composeEnhancer(applyMiddleware(routerMiddleware(history)))
)

// branch.init('key_live_paUE6jHsJHhXML6p4CWa2libBta6AdK8', function(err, data) {
//   console.log('branch err', err)
//   console.log('branch data', data)
// })

const ReactApp = observer(() => {
  if (!userStore.hydrated) {
    return <div />
  }
  return <App history={history} />
})

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <MobxProvider userStore={userStore}>
        <ReactApp />
      </MobxProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
