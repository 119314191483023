import gql from 'graphql-tag'
import {compose, graphql} from 'react-apollo'
import {connect} from 'react-redux'
import ConsultationViewError from '../components/ConsultationViewError'

export default compose(
  connect(),
  graphql(
    gql`
      query ConsultationPractitioner($id: ID!) {
        consultationPractitioner(id: $id) {
          id
          name
        }
      }
    `,
    {
      options: ({consultationId}) => ({
        variables: {id: consultationId},
      }),
      props: ({data: {consultationPractitioner, loading} = {}}) => ({
        doctor: consultationPractitioner,
        loading,
      }),
    }
  )
)(ConsultationViewError)
