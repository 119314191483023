import {graphql, compose} from 'react-apollo'
import Index from '../components/Index'
import {connect} from 'react-redux'
import gql from 'graphql-tag'

export default compose(
  connect(),
  graphql(
    gql`
      query Me {
        me {
          id
        }
      }
    `,
    {
      props: ({loading, data: {me} = {}}) => ({me, loading}),
    }
  )
)(Index)
