import matchSorter from 'match-sorter'
import * as moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import ReactTable from 'react-table'
import {Button, Container, Header, List, Segment} from 'semantic-ui-react'

// export default ({consultations, loading, fetchMore, networkStatus}) => {

export default class Consultations extends React.Component {
  render() {
    const {
      doctors,
      consultations,

      unpaidPractitionersLoading,
      unpaidPractitioners,

      loading = true,
      fetchMore,
      first,
      // error,
      history,
      // networkStatus,
      setFirst,
      setAfter,
      setFilter,
      setSort,
    } = this.props

    return (
      <>
        {!unpaidPractitionersLoading && unpaidPractitioners.length > 0 && (
          <Container fluid style={{padding: 20}}>
            <Segment>
              <Header as="h3">Unpaid Practitioners</Header>

              <List divided relaxed>
                {unpaidPractitioners.map((practitioner) => {
                  return (
                    <List.Item key={`practitioner-${practitioner.id}`}>
                      <List.Icon
                        name="user"
                        size="large"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header
                          as={Link}
                          to={`/doctors/${practitioner.id}`}
                        >
                          {practitioner.name}
                        </List.Header>
                        <List.Description>
                          {practitioner.name} has unpaid consultations{' '}
                          <Link to={`/doctors/${practitioner.id}`}>
                            Subscribe Now
                          </Link>{' '}
                          to access {practitioner.name}'s consultations
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  )
                })}
              </List>
            </Segment>
          </Container>
        )}
        <div
          fluid
          style={{
            padding: 20,
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Button
            onClick={() => {
              history.push(`/consultations/export`)
            }}
            primary
          >
            Export consultation items
          </Button>
          <Button
            onClick={() => {
              history.push(`/consultations/delete`)
            }}
            secondary
          >
            Delete old consultations
          </Button>
        </div>
        <ReactTable
          data={consultations && consultations.edges ? consultations.edges : []}
          pageSize={first}
          showPageSizeOptions={true}
          pageSizeOptions={[10, 20, 30, 50, 100]}
          defaultPageSize={20}
          pages={
            consultations && consultations.pageInfo
              ? consultations.pageInfo.lastPage
              : 0
          }
          loading={loading}
          loadingText={'Loading...'}
          filterable
          manual
          className="-striped -highlight"
          getTrProps={(state, rowInfo, column, instance) => ({
            onClick: (e) => {
              history.push(`/consultations/${rowInfo.row._original.node.id}`)
            },
          })}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          onPageSizeChange={(pageSize, pageIndex) => {
            console.log(pageSize)
            setFirst(pageSize)
          }}
          onPageChange={(data) => {
            setAfter(data * first)
          }}
          onFilteredChange={(filtered, column) => {
            var result = {}
            for (var i = 0; i < filtered.length; i++) {
              result[filtered[i].id] = filtered[i].value
            }
            setFilter(result)
          }}
          onSortedChange={(newSorted, column, shiftKey) => {
            var result = {}
            for (var i = 0; i < newSorted.length; i++) {
              result[newSorted[i].id] = newSorted[i].desc
            }
            setSort(result)
          }}
          onFetchData={(state, instance) => {
            if (!consultations || !consultations.pageInfo) {
              return
            }
            fetchMore({
              variables: {
                after: consultations.pageInfo.endCursor,
              },
              updateQuery: (prevResult, {fetchMoreResult}) => {
                const newEdges = fetchMoreResult.consultations.edges
                const pageInfo = fetchMoreResult.consultations.pageInfo
                return newEdges.length
                  ? {
                      consultations: {
                        ...consultations,
                        __typename: prevResult.consultations.__typename,
                        chats: {
                          ...consultations,
                          edges: [
                            ...prevResult.consultations.edges,
                            ...newEdges,
                          ],
                          pageInfo,
                        },
                      },
                    }
                  : prevResult
              },
            })
          }}
          columns={[
            {
              id: 'createdAt',
              Header: 'Date',
              accessor: 'node.dateTime',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['node.dateTime'],
                }),
              filterAll: true,
              Cell: ({original, value}) =>
                moment(value).format('Do MMM YY, hh:mm a '),
              filterable: false,
            },
            {
              id: 'duration',
              Header: 'Duration',
              accessor: 'node.length',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['node.duration'],
                }),
              Cell: ({original, value}) => {
                var hours = value.hours ? value.hours + ' Hours' : ''
                var minutes = value.minutes ? value.minutes + ' Minutes' : ''
                return `${hours} ${minutes}`
              },
              filterable: false,
              filterAll: true,
            },
            {
              id: 'patientName',
              Header: 'Patient',
              accessor: 'node.patient.firstName',
              sortable: false,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['node.patient.firstName'],
                }),
              Cell: ({original, value}) => {
                var firstName = original.node.patient.firstName
                var lastName = original.node.patient.lastName
                return `${firstName} ${lastName}`
              },
              filterAll: true,
            },
            {
              id: 'doctorId',
              Header: 'Doctor',
              accessor: 'node.practitioner.name',
              sortable: false,
              filterMethod: (filter, consultation) => {
                if (filter.value === 'all') {
                  return true
                }
                return consultation.practitioner.id === filter.value
              },
              Filter: ({filter, onChange}) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{width: '100%'}}
                  value={filter ? filter.value : 'all'}
                >
                  <option key="all" value="all">
                    *All*
                  </option>

                  {doctors.map((doctor) => {
                    return (
                      <option key={doctor.id} value={doctor.id}>
                        {doctor.name}
                      </option>
                    )
                  })}
                </select>
              ),
              filterAll: true,
            },
          ]}
        />
      </>
    )
  }
}
