import React from 'react'
import {
  Container,
  Header,
  Segment,
  Form,
  Checkbox,
  Dimmer,
  Loader,
} from 'semantic-ui-react'
import DoctorSubscription from '../containers/DoctorSubscription'
import PrettyError from './PrettyError'
import userStore from '../mobx/user'

export default ({
  title,
  loading,
  errors,
  error,
  refetch,
  me,
  toggleActive,
  togglingActive,
  setToggling,
}) => {
  console.log('inside render')
  console.log('me', me)

  if (error) {
    console.log('error')
    return <PrettyError error={error} onRetry={refetch} />
  }

  if (!me) {
    // Do loading
    return <></>
  }

  let doctor = me.practice.doctor

  return (
    <Container>
      <Segment basic>
        {loading === true || togglingActive === true ? (
          <Dimmer active inverted>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
        ) : null}

        <Header as="h2">{title}</Header>
        <Form loading={loading} autoComplete="false">
          <Form.Field disabled={true}>
            <label>Name</label>
            <Form.Input name="name" value={doctor.name} />
          </Form.Field>

          <Form.Field disabled={true}>
            <label>AHPRA</label>
            <Form.Input name="ahpra" value={doctor.ahpra} />
          </Form.Field>

          <Form.Field disabled={true}>
            <label>Email</label>
            <Form.Input name="email" type={'email'} value={doctor.email} />
          </Form.Field>

          <Form.Field disabled={true}>
            <label>Specialty</label>
            <Form.Input
              name="email"
              type={'email'}
              value={!!doctor.specialty ? doctor.specialty.name : 'not set'}
            />
          </Form.Field>

          <Form.Field>
            <label>Account Enabled</label>
            <Checkbox
              toggle
              label="Yes"
              name="isActive"
              checked={doctor.isActive}
              onChange={async (event, {name, checked}) => {
                setToggling(true)
                await toggleActive({
                  variables: {
                    practiceId: userStore.practiceId,
                    userId: doctor.id,
                  },
                })
                setToggling(false)
                // setFieldValue(name, checked)
                // todo update it here
              }}
            />
          </Form.Field>
        </Form>

        {doctor && <DoctorSubscription doctor={doctor} />}
      </Segment>
    </Container>
  )
}
