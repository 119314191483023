import {graphql, compose} from 'react-apollo'
import Consultations from '../components/Consultations'
import {connect} from 'react-redux'
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'
import {withState} from 'recompose'
import {observer, inject} from 'mobx-react'
import userStore from '../mobx/user'

export default compose(
  connect(),
  withState('first', 'setFirst', 20),
  withState('after', 'setAfter', 0),
  withState('filter', 'setFilter', null),
  withState('sort', 'setSort', {createdAt: true}),
  graphql(
    gql`
      query Doctors($practiceId: ID!) {
        me {
          practice(practiceId: $practiceId) {
            id
            doctors {
              id
              name
            }
          }
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
          practiceId: userStore.practiceId,
        },
      }),
      props: ({error, networkStatus, data: {me = {}, loading}}) => {
        return {
          loading,
          doctors:
            me && me.practice && me.practice.doctors ? me.practice.doctors : [],
        }
      },
    }
  ),
  graphql(
    gql`
      query unpaidPractitioners($practiceId: ID!) {
        unpaidPractitioners(practiceId: $practiceId) {
          id
          name
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
          practiceId: userStore.practiceId,
        },
      }),
      props: ({
        // error,
        // networkStatus,
        data: {unpaidPractitioners = [], loading},
      }) => {
        return {
          unpaidPractitionersLoading: loading,
          unpaidPractitioners,
        }
      },
    }
  ),
  graphql(
    gql`
      query Consultations(
        $practiceId: ID!
        $first: Int!
        $after: Int!
        $filter: ConsultationFilterInput
        $sort: ConsultationSortInput
      ) {
        consultations(
          first: $first
          after: $after
          filter: $filter
          sort: $sort
          practiceId: $practiceId
        ) {
          edges {
            node {
              id
              dateTime
              length {
                seconds
                minutes
                hours
              }
              patient {
                id
                firstName
                lastName
              }
              practitioner {
                id
                name
                specialty {
                  id
                  name
                }
              }
              createdAt
            }
            cursor
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            total
            count
            startCursor
            endCursor
            currentPage
            lastPage
          }
        }
      }
    `,
    {
      options: ({first, after, filter, sort}) => ({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
          first,
          after,
          filter,
          sort,
          practiceId: userStore.practiceId,
        },
      }),
      props: ({
        error,
        networkStatus,
        data: {consultations, fetchMore, refetch, loading} = {},
      }) => ({
        consultations,
        loading,
        error,
        fetchMore,
        refetch,
        networkStatus,
      }),
    }
  ),
  withApollo
)(inject('userStore')(observer(Consultations)))
