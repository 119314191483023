import {graphql, compose} from 'react-apollo'
import {connect} from 'react-redux'
import {withState} from 'recompose'
import gql from 'graphql-tag'
import Recover from '../components/Recover'

export default compose(
  connect(),
  withState('isRequesting', 'setIsRequesting', false),
  graphql(
    gql`
      query Me {
        me {
          id
        }
      }
    `,
    {
      props: ({loading, data: {me} = {}}) => ({me, loading}),
    }
  ),
  graphql(
    gql`
      mutation RequestPassreset($email: String!) {
        requestPassreset(email: $email)
      }
    `,
    {
      name: 'requestPassreset',
      options: {
        variables: {
          role: 1,
        },
      },
    }
  )
)(Recover)
