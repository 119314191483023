import React from 'react'
import {
  Container,
  Header,
  Segment,
  Form,
  Button,
  Dimmer,
  Checkbox,
  Loader,
} from 'semantic-ui-react'

import PrettyError from './PrettyError'

export default ({
  values,
  handleChange,
  handleSubmit,
  submitting = false,
  title,
  loading,
  errors,
  error,
  refetch,
  setFieldValue,
  passwordRequired,
  isSubmitting,
  me,
}) => {
  if (error) {
    return <PrettyError error={error} onRetry={refetch} />
  }

  // if (!me) {
  if (loading) {
    // Do loading
    return <></>
  }

  let administrator = (me && me.practice && me.practice.admin) || null
  let practice = me && me.practice
  let isMe = false

  if (administrator && me) {
    isMe = me.id === administrator.id
  }

  const editing = administrator && administrator.id

  const adminIsNotOwner =
    me &&
    practice &&
    administrator &&
    Number(administrator.id) !== Number(practice.owner.id)

  const isOwner = me && practice && Number(me.id) === Number(practice.owner.id)

  // me &&
  // administrator &&
  // me.id !== administrator.id &&
  // (administrator.id === administrator.practice.owner.id &&
  //   me.id === administrator.practice.owner.id)

  return (
    <Container>
      <Segment basic>
        {loading === true || isSubmitting === true ? (
          <Dimmer active inverted>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
        ) : null}

        <Header as="h2">{title}</Header>
        <Form
          loading={submitting}
          onSubmit={handleSubmit}
          autoComplete="false"
          disabled={!isMe}
        >
          <Form.Field required error={errors.name ? true : false}>
            <label>Name</label>
            <Form.Input
              name="name"
              value={values.name}
              onChange={handleChange}
              disabled={editing && !isMe}
              error={
                errors && errors.name
                  ? {content: errors.name, pointing: 'above'}
                  : false
              }
            />
          </Form.Field>

          <Form.Field required error={errors.email ? true : false}>
            <label>Email</label>
            <Form.Input
              name="email"
              type={'email'}
              value={values.email}
              disabled={editing && !isMe}
              onChange={handleChange}
              error={
                errors && errors.email
                  ? {content: errors.email, pointing: 'above'}
                  : false
              }
            />
          </Form.Field>

          {isMe && (
            <Form.Field
              required={passwordRequired}
              error={errors.password ? true : false}
            >
              <label>Set Password</label>
              <Form.Input
                name="password"
                type={'password'}
                required={passwordRequired}
                value={values.password}
                onChange={handleChange}
                error={
                  errors && errors.password
                    ? {content: errors.password, pointing: 'above'}
                    : false
                }
              />
            </Form.Field>
          )}

          {adminIsNotOwner && !isMe && isOwner && (
            <Form.Field>
              <label>Account Enabled</label>
              <Checkbox
                toggle
                label="Yes"
                name="isActive"
                checked={values.isActive}
                onChange={(event, {name, checked}) => {
                  setFieldValue(name, checked)
                }}
              />
            </Form.Field>
          )}

          <Button
            style={{
              marginTop: '20px',
            }}
            primary
            type="submit"
            disabled={Object.keys(errors).length > 0}
          >
            {administrator && <span>Save</span>}
            {!administrator && <span>Add</span>}
          </Button>
        </Form>
      </Segment>
    </Container>
  )
}
