import React from 'react'
import {
  Container,
  Grid,
  Header,
  Segment,
  Divider,
  Placeholder,
  Dimmer,
  Icon,
  Button,
  Loader,
} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

export default ({doctor, loading, noAccess = false}) => (
  <Container>
    <Segment style={{marginTop: '20px', minHeight: '400px'}}>
      <Dimmer active inverted>
        <Loader active={loading} disabled={!loading}>
          Loading
        </Loader>

        {!loading && (
          <Container>
            <Grid centered columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <div>
                    <Header as="h2" icon textAlign="center">
                      <Icon fitted name="ban" />
                      <Header.Content>
                        Cannot access consultation
                      </Header.Content>
                    </Header>
                    {!noAccess && (
                      <p
                        style={{
                          color: 'black',
                          textAlign: 'center',
                        }}
                      >
                        {doctor && doctor.name
                          ? `${doctor.name},`
                          : 'The practitioner'}{' '}
                        who performed this consultation, no longer has an active
                        subscription within your practice, please activate their
                        subscription to view their consultation details.
                      </p>
                    )}
                    {noAccess && (
                      <p
                        style={{
                          color: 'black',
                          textAlign: 'center',
                        }}
                      >
                        You do not have permission to access another doctors
                        consultation.
                      </p>
                    )}
                  </div>

                  {!noAccess && (
                    <div
                      style={{
                        marginTop: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        as={Link}
                        to={`/doctors/${doctor.id}`}
                        style={{
                          margin: '0 auto',
                        }}
                        icon
                        labelPosition="left"
                        primary
                      >
                        <Icon name="eye" />
                        Go to {doctor.name}
                      </Button>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        )}
      </Dimmer>

      <Grid
        style={{
          padding: '20px',
        }}
      >
        <Grid.Row
          style={{
            display: 'block',
          }}
        >
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>

          <Placeholder>
            <Placeholder.Line length={'very short'} />
          </Placeholder>
        </Grid.Row>

        <Divider />

        <Grid.Row>
          <Header as="h5">Billing Items</Header>
        </Grid.Row>

        <Grid.Row
          style={{
            display: 'block',
          }}
        >
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Grid.Row>
      </Grid>
    </Segment>
  </Container>
)
