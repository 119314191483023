import {push} from 'connected-react-router'
import {Formik} from 'formik'
import {NavLink} from 'react-router-dom'
import React from 'react'
import {
  Button,
  Container,
  Divider,
  Image,
  Input,
  Message,
  Segment,
  Dimmer,
  Loader,
  Form,
  Header,
} from 'semantic-ui-react'
import logo from '../assets/weblogo.png'
import {toast} from 'react-toastify'
import '../styles/Login.scss'

export default ({
  requestPassreset,
  dispatch,
  isRequesting,
  setIsRequesting,
}) => {
  return (
    <Container textAlign="center">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '20vh',
        }}
      >
        <Image src={logo} style={{maxHeight: '20vh'}} />
      </div>

      <Segment className="login-segment" padded="very">
        {isRequesting === true ? (
          <Dimmer active inverted>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
        ) : null}

        <Header as="h2" dividing>
          Password Recovery
        </Header>

        <Formik
          initialValues={{email: '', practiceCode: ''}}
          onSubmit={async (values, {resetForm}) => {
            try {
              setIsRequesting(true)
              await requestPassreset({variables: values})
              toast.success(
                "If there was an account with the given email, an email to reset the accounts' password has been sent",
                {}
              )
            } catch (e) {
              toast.error('Unable to reset account, try again later', {})
            } finally {
              setIsRequesting(false)
            }
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props

            return (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Form.Field>
                  <Input
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.email && touched.email
                        ? 'text-input error'
                        : 'text-input'
                    }
                    name="email"
                    fluid
                    placeholder="Email"
                  />

                  {errors.email && touched.email && (
                    <Message
                      icon="warning circle"
                      error
                      content={errors.email}
                    />
                  )}
                </Form.Field>

                {/* <Form.Field>
                  <Input
                    id="practiceCode"
                    type="practiceCode"
                    value={values.practiceCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.practiceCode && touched.practiceCode
                        ? 'text-input error'
                        : 'text-input'
                    }
                    name="practiceCode"
                    fluid
                    placeholder="Practice Code"
                  />

                  {errors.practiceCode && touched.practiceCode && (
                    <Message
                      icon="warning circle"
                      error
                      content={errors.practiceCode}
                    />
                  )}
                </Form.Field> */}

                <Divider hidden as="br" />
                <Button type="submit" primary fluid>
                  Reset Account
                </Button>
              </Form>
            )
          }}
        </Formik>
        <div style={{marginTop: 10}}>
          <NavLink to="/login">Login</NavLink>
        </div>
      </Segment>
    </Container>
  )
}
