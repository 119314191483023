import React from 'react'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import {Link} from 'react-router-dom'
import {Button, Container, Icon, Dimmer, Loader, Label} from 'semantic-ui-react'
import PrettyError from './PrettyError'

export default ({me, loading, history, error, refetch}) => {
  if (error) {
    return <PrettyError error={error} onRetry={refetch} />
  }

  return (
    <>
      {loading === true ? (
        <Dimmer active inverted>
          <Loader size="medium">Loading</Loader>
        </Dimmer>
      ) : null}

      <Container fluid style={{padding: 20}}>
        <Button
          icon
          primary
          labelPosition="left"
          as={Link}
          to="/doctors/invite"
        >
          <Icon name="add circle" />
          Invite Doctor
        </Button>
      </Container>
      <ReactTable
        data={
          me && me.practice && me.practice.doctors ? me.practice.doctors : []
        }
        filterable
        className="-striped -highlight"
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        getTrProps={(state, rowInfo, column, instance) => ({
          onClick: e => {
            history.push(`/doctors/${rowInfo.row._original.id}`)
          },
        })}
        columns={[
          // {
          //   Header: 'AHPRA',
          //   accessor: 'ahpra',
          //   filterMethod: (filter, rows) =>
          //     matchSorter(rows, filter.value, {keys: ['ahpra']}),
          //   filterAll: true,
          // },
          {
            Header: 'Name',
            accessor: 'name',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {keys: ['name']}),
            filterAll: true,
          },
          {
            Header: 'Account Enabled',
            accessor: 'isActive',
            filterable: false,
            sortable: false,
            Cell: ({original, value}) => {
              return (
                <Label
                  className={value ? 'primary' : 'red'}
                  basic
                  size={'mini'}
                >
                  {value ? 'Enabled' : 'Disabled'}
                </Label>
              )
            },
          },
          {
            Header: 'Email Status',
            accessor: 'emailConfirmed',
            filterable: false,
            sortable: false,
            Cell: ({original, value}) => {
              return (
                <Label
                  className={value ? 'primary' : 'red'}
                  basic
                  size={'mini'}
                >
                  {value ? 'Confirmed' : 'Unconfirmed'}
                </Label>
              )
            },
          },
          {
            Header: 'Specialty',
            accessor: 'specialty.name',
            Cell: ({original, value}) => {
              return (
                <Label basic size={'mini'}>
                  {value}
                </Label>
              )
            },
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {keys: ['specialty.name']}),
            filterAll: true,
          },
        ]}
      />
    </>
  )
}
