import _ from 'lodash'
import * as moment from 'moment'
import React, {Component} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {toast} from 'react-toastify'
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Dropdown,
  Grid,
  Header,
  Loader,
  Segment,
} from 'semantic-ui-react'
import Modal from '../common/Modal'
import userStore from '../mobx/user'
import {parseError} from '../utils'

var endingDate = new Date()
endingDate.setHours(0)
endingDate.setMonth(endingDate.getMonth() - 12)

const dropdownOptions = [
  {
    key: 3,
    value: 3,
    text: 'Over 3 months old',
  },
  {
    key: 6,
    value: 6,
    text: 'Over 6 months old',
  },
  {
    key: 12,
    value: 12,
    text: 'Over 12 months old',
  },
]

class ConsultationsDelete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      showModal: false,
      loading: false,
      option: 12,
      selectedTimeframe: 12,
      filtyBoy: {
        end: endingDate,
        doctorId: null,
        patientId: null,
      },
    }
  }

  handleTimeSelection = (e, {value}) => {
    var endingDate = new Date()
    endingDate.setHours(0)
    endingDate.setMonth(endingDate.getMonth() - value)

    const gweg = {...this.state.filtyBoy, end: endingDate}
    this.setState({
      option: value,
      endingDate,
      filtyBoy: gweg,
    })
    this.props.setFilter(gweg)
  }

  startSubmit = async () => {
    const {primeDeleteConsultations, deleteConsultations} = this.props
    const {filtyBoy} = this.state

    if (
      confirm(
        'Are you sure you wish to continue?  You cannot recover this data once deleted.'
      )
    ) {
      this.setState({submitting: true})

      const deletionList = primeDeleteConsultations.map((e) => Number(e.id))
      try {
        var resp = await deleteConsultations({
          variables: {
            practiceId: userStore.practiceId,
            consultations: deletionList,
            end: filtyBoy.end,
          },
        })

        console.log('@@@resp', resp)
        this.setState({showModal: false})
      } catch (err) {
        console.log('@@@@@ errrr', err)
        toast.error(parseError(err), {})
      } finally {
        this.setState({submitting: false})
      }
    }
  }

  render() {
    const {loading = true, primeDeleteConsultations} = this.props
    const {option, showModal, submitting} = this.state
    return (
      <Container>
        <Segment basic />
        <Segment basic>
          {loading && (
            <Dimmer active inverted>
              <Loader size="medium"></Loader>
            </Dimmer>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Header as="h2" className="no-print">
              Delete old consultations{' '}
              {primeDeleteConsultations &&
                primeDeleteConsultations.length > 0 &&
                `: ${primeDeleteConsultations.length} found`}
            </Header>
            {primeDeleteConsultations && primeDeleteConsultations.length > 0 && (
              <Button
                onClick={() => {
                  this.setState({showModal: true})
                  // this.startSubmit()
                }}
                color="red"
              >
                Delete Consultations
              </Button>
            )}
          </div>
        </Segment>
        <Segment basic>
          <Header as="h4" className="no-print">
            Time period
          </Header>
          <Dropdown
            item
            simple
            labeled
            text={`Over ${option} months old`}
            disabled={submitting}
            direction="right"
            value={option}
            onChange={this.handleTimeSelection}
            options={dropdownOptions}
            // header={
            //   <Header as="h4" className="no-print">
            //     Time period
            //   </Header>
            // }
          />

          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <Radio
              label="Over three months old"
              value={3}
              checked={option === 3}
              onChange={this.handleTimeSelection}
            />
            <Radio
              label="Over six months old"
              value={6}
              checked={option === 6}
              onChange={this.handleTimeSelection}
            />
            <Radio
              label="Over one year old"
              value={12}
              checked={option === 12}
              onChange={this.handleTimeSelection}
            />
          </div> */}
        </Segment>

        <div className="no-print">
          <Divider />
        </div>

        <div style={{marginTop: 18}}>
          {primeDeleteConsultations &&
            primeDeleteConsultations.length > 0 &&
            _.orderBy(primeDeleteConsultations, ['dateTime'], ['asc']).map(
              (e, i) => (
                <Segment key={e.id}>
                  <Grid
                    style={{
                      padding: '10px 20px',
                    }}
                  >
                    <Header as="h3" style={{padding: 0}}>
                      {moment(e.dateTime).format('DD/MM/YYYY - hh:mma')}
                    </Header>

                    <Grid.Row style={{padding: 0}} columns={4}>
                      <Grid.Column>
                        <Header sub>Duration</Header>
                        <div>
                          {e.length.hours > 0 ? `${e.length.hours} Hours ` : ''}
                          {e.length.minutes > 0
                            ? `${e.length.minutes} Minutes`
                            : ''}
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header sub>Patient</Header>
                        <div>
                          {e.patient.firstName} {e.patient.lastName}
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header sub>Pracitioner</Header>
                        <div>{e.practitioner.name}</div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header sub>Location</Header>
                        <div>
                          {e.hospital
                            ? e.hospital.name
                            : e.patient.hospital.name}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              )
            )}
        </div>
        <div style={{height: 40}} />

        <Modal canClose={false} show={showModal}>
          <Header as="h2" style={{padding: 0, textAlign: 'center'}}>
            Confirm Deletion
          </Header>
          <Header
            as="h3"
            style={{
              padding: 0,
              textAlign: 'center',
              maxWidth: 450,
              margin: '0 auto',
            }}
          >
            Are you sure you want to delete these consultations? This cannot be
            undone and you will lose this data forever.
          </Header>
          <div style={{height: 30}} />
          {submitting && (
            <Dimmer active inverted>
              <Loader size="medium"></Loader>
            </Dimmer>
          )}
          <div className="row" style={{justifyContent: 'space-evenly'}}>
            <Button
              onClick={() => {
                if (!submitting) this.setState({showModal: false})
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (!submitting) this.startSubmit()
              }}
              color="red"
            >
              Delete
            </Button>
          </div>
        </Modal>
      </Container>
    )
  }
}

export default ConsultationsDelete
