import {graphql, compose} from 'react-apollo'
import {connect} from 'react-redux'
import {withState} from 'recompose'
import gql from 'graphql-tag'
import ActivateAccount from '../components/ActivateAccount'

export default compose(
  connect(),
  // withState('isRequesting', 'setIsRequesting', false),
  graphql(
    gql`
      mutation ActivateAccount($hash: String!) {
        activate(hash: $hash)
      }
    `,
    {
      name: 'activateAccount',
      // options: ({hash}) => ({
      //   variables: {hash},
      // }),
    }
  )
)(ActivateAccount)
