import _ from 'lodash'
import * as moment from 'moment'
import React from 'react'
import {CSVLink} from 'react-csv'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Link} from 'react-router-dom'
import {
  Button,
  Container,
  Dimmer,
  Divider,
  Dropdown,
  Grid,
  Header,
  List,
  Loader,
  Segment,
  Table,
} from 'semantic-ui-react'

var startingDate = new Date()
startingDate.setHours(0)
startingDate.setMonth(startingDate.getMonth() - 1)
var endingDate = new Date()
endingDate.setHours(23)
endingDate.setMinutes(59)

export default class Consultations extends React.Component {
  state = {
    startDate: startingDate,
    endDate: endingDate,
    filtyBoy: {
      start: startingDate,
      end: endingDate,
      doctorId: null,
      patientId: null,
    },
  }
  handleStartChange = (date) => {
    date.setHours(0)
    const gweg = {...this.state.filtyBoy, start: date}
    this.setState({
      startDate: date,
      filtyBoy: gweg,
    })
    this.props.setFilter(gweg)
  }
  handleEndChange = (endDate) => {
    endDate.setHours(23)
    endDate.setMinutes(59)
    const gweg = {...this.state.filtyBoy, end: endDate}
    this.setState({
      endDate: endDate,
      filtyBoy: gweg,
    })
    this.props.setFilter(gweg)
  }
  handleDoctorChange = (e, data) => {
    const gweg = {...this.state.filtyBoy, doctorId: data.value}
    console.log(gweg.doctorId)
    this.setState({
      filtyBoy: gweg,
    })
    this.props.setFilter(gweg)
  }
  handlePatientChange = (e, data) => {
    const gweg = {...this.state.filtyBoy, patientId: data.value}
    console.log(gweg.patientId)
    this.setState({
      filtyBoy: gweg,
    })
    this.props.setFilter(gweg)
  }

  prepDoctors = (doctors) => {
    const goowoo = [
      {
        key: null,
        text: 'All',
        value: null,
      },
    ]
    _.orderBy(doctors, ['name'], ['asc']).forEach((d) => {
      goowoo.push({
        key: d.id,
        text: d.name,
        value: d.id,
      })
    })
    return goowoo
  }

  prepPatients = (doctors) => {
    const goowoo = [
      {
        key: null,
        text: 'All',
        value: null,
      },
    ]
    _.orderBy(doctors, ['firstName'], ['asc']).forEach((d) => {
      goowoo.push({
        key: d.id,
        text: `${d.firstName} ${d.lastName}${
          d.dateOfBirth
            ? ` - (${moment(d.dateOfBirth).format('DD/MM/YYYY')})`
            : ''
        }`,
        value: d.id,
      })
    })
    return goowoo
  }

  getCSV = (data) => {
    const arrayToUse = []
    data.map((e) => {
      if (e.billingItems.length === 0) {
        arrayToUse.push({
          practitioner: e.practitioner.name,
          hospital: e.hospital ? e.hospital.name : e.patient.hospital.name,
          referrer: e.patient.referring
            ? e.patient.referring.map((e) => e.name).toString()
            : '',
          patient: `${e.patient.firstName} ${e.patient.lastName}`,
          DOB: !!e.patient.dateOfBirth
            ? moment(e.patient.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
            : '',
          mbsItem: '',
          mbsQty: '',
          mbsStatus: '',
          consultationId: e.id,
          consultationDate: moment(e.dateTime).format('DD/MM/YYYY'),
          consultationLength: `${
            e.length.hours ? ('00' + e.length.hours).slice(-2) : '00'
          }:${e.length.minutes ? ('00' + e.length.minutes).slice(-2) : '00'}:${
            e.length.seconds ? ('00' + e.length.seconds).slice(-2) : '00'
          }`,
          diagnosis: e.diagnosis,
        })
      } else {
        e.billingItems.map((bi) => {
          arrayToUse.push({
            practitioner: e.practitioner.name,
            hospital: e.hospital ? e.hospital.name : e.patient.hospital.name,
            referrer: e.patient.referring
              ? e.patient.referring.map((e) => e.name).toString()
              : '',
            patient: `${e.patient.firstName} ${e.patient.lastName}`,
            DOB: !!e.patient.dateOfBirth
              ? moment(e.patient.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : '',
            mbsItem: bi.mbsItem.ItemNum,
            mbsQty: bi.quantity,
            mbsStatus: bi.status,
            consultationId: e.id,
            consultationDate: moment(e.dateTime).format('DD/MM/YYYY'),
            consultationLength: `${
              e.length.hours ? ('00' + e.length.hours).slice(-2) : '00'
            }:${
              e.length.minutes ? ('00' + e.length.minutes).slice(-2) : '00'
            }:${e.length.seconds ? ('00' + e.length.seconds).slice(-2) : '00'}`,
            diagnosis: e.diagnosis,
          })
        })
      }
    })

    return arrayToUse
  }

  render() {
    const {
      doctors,
      patients,
      exportConsultations,
      unpaidPractitionersLoading,
      unpaidPractitioners,
      loading = true,
    } = this.props
    const doctorOptions =
      doctors && doctors.length > 0 ? this.prepDoctors(doctors) : []
    const patientOptions =
      patients && patients.length > 0 ? this.prepPatients(patients) : []

    const hasExports = exportConsultations && exportConsultations.length > 0
    let dataCSV = []
    if (hasExports) {
      dataCSV = this.getCSV(exportConsultations)
    }

    return (
      <Container>
        <Segment basic />
        <Segment basic>
          {loading && (
            <Dimmer active inverted>
              <Loader size="medium"></Loader>
            </Dimmer>
          )}
          <Header as="h2" className="no-print">
            Export Consultation Range
          </Header>

          {/* Display unpaid practitioners */}
          {!unpaidPractitionersLoading && unpaidPractitioners.length > 0 && (
            <Container fluid style={{padding: 20}} className="no-print">
              <Segment>
                <Header as="h3">Unpaid Practitioners</Header>
                <List divided relaxed>
                  {unpaidPractitioners.map((practitioner) => {
                    return (
                      <List.Item key={`practitioner-${practitioner.id}`}>
                        <List.Icon
                          name="user"
                          size="large"
                          verticalAlign="middle"
                        />
                        <List.Content>
                          <List.Header
                            as={Link}
                            to={`/doctors/${practitioner.id}`}
                          >
                            {practitioner.name}
                          </List.Header>
                          <List.Description>
                            {practitioner.name} has unpaid consultations{' '}
                            <Link to={`/doctors/${practitioner.id}`}>
                              Subscribe Now
                            </Link>{' '}
                            to access {practitioner.name}'s consultations
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    )
                  })}
                </List>
              </Segment>
            </Container>
          )}
          <Container className="no-print">
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header
                    as="h2"
                    sub
                    style={{display: 'inline-block', marginBottom: 9}}
                  >
                    Starting date
                  </Header>
                  <div>
                    <DatePicker
                      style={{
                        height: 40,
                        backgroundColor: 'red',
                      }}
                      selected={this.state.startDate}
                      onChange={this.handleStartChange}
                      dateFormat="dd/MM/yyyy"
                      className="customDatePicker"
                    />
                  </div>
                  <Header
                    as="h2"
                    sub
                    style={{
                      display: 'inline-block',
                      marginTop: 18,
                      marginBottom: 9,
                    }}
                  >
                    End date
                  </Header>
                  <div>
                    <DatePicker
                      selected={this.state.endDate}
                      onChange={this.handleEndChange}
                      dateFormat="dd/MM/yyyy"
                      className="customDatePicker"
                    />
                  </div>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header
                    as="h2"
                    sub
                    style={{
                      display: 'inline-block',
                      marginBottom: 9,
                    }}
                  >
                    Doctor (Optional)
                  </Header>
                  <div>
                    <Dropdown
                      search
                      fluid
                      placeholder="Select Doctor"
                      selection
                      options={doctorOptions}
                      onChange={this.handleDoctorChange}
                    />
                  </div>
                  <Header
                    as="h2"
                    sub
                    style={{
                      display: 'inline-block',
                      marginBottom: 9,
                      marginTop: 18,
                    }}
                  >
                    Patient (Optional)
                  </Header>
                  <div>
                    <Dropdown
                      search
                      fluid
                      placeholder="Select Patient"
                      selection
                      options={patientOptions}
                      onChange={this.handlePatientChange}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row>
                <Button onClick={() => {}} primary>
                  Fetch Consultations
                </Button>
              </Grid.Row> */}
            </Grid>
          </Container>
          <div style={{padding: 20}} className="no-print">
            <Divider />
          </div>

          <div style={{marginTop: 18}}>
            {hasExports && (
              <CSVLink
                data={dataCSV}
                filename={`users_export_${moment().format(
                  'DD_MM_YY_HHmm'
                )}.csv`}
              >
                <Button onClick={() => {}} secondary>
                  Download as CSV
                </Button>
              </CSVLink>
            )}

            {exportConsultations &&
              exportConsultations.length > 0 &&
              _.orderBy(exportConsultations, ['dateTime'], ['asc']).map((e) => (
                <Segment key={e.id} style={{marginBottom: 8}}>
                  <Grid
                    style={{
                      padding: '10px 20px',
                    }}
                  >
                    <Header as="h3" style={{padding: 0}}>
                      {moment(e.dateTime).format('DD/MM/YYYY - hh:mma')}
                    </Header>
                    <Grid.Row style={{padding: 0}} columns={4}>
                      <Grid.Column>
                        <Header sub>Duration</Header>
                        <div>
                          {e.length.hours > 0 ? `${e.length.hours} Hours ` : ''}
                          {e.length.minutes > 0
                            ? `${e.length.minutes} Minutes`
                            : ''}
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header sub>Patient</Header>
                        <div>
                          {e.patient.firstName} {e.patient.lastName}
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header sub>Pracitioner</Header>
                        <div>{e.practitioner.name}</div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header sub>Location</Header>
                        <div>
                          {e.hospital
                            ? e.hospital.name
                            : e.patient.hospital.name}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {e.billingItems.length > 0 && (
                    <Table celled padded>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell singleLine>
                            MBS ItemNum
                          </Table.HeaderCell>
                          <Table.HeaderCell>Quantity</Table.HeaderCell>
                          <Table.HeaderCell>MBS Description</Table.HeaderCell>
                          <Table.HeaderCell style={{width: 80}}>
                            Status
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        {e.billingItems.map((billingItem) => (
                          <Table.Row key={billingItem.id}>
                            <Table.Cell textAlign="center">
                              {billingItem.mbsItem.ItemNum}
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                              {billingItem.quantity}
                            </Table.Cell>
                            <Table.Cell>
                              {billingItem.mbsItem.Description}
                            </Table.Cell>
                            <Table.Cell style={{width: 80}}>
                              {billingItem.status}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  )}
                </Segment>
              ))}
          </div>
        </Segment>
      </Container>
    )
  }
}
