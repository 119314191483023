import {push} from 'connected-react-router'
import {Formik} from 'formik'
import {NavLink} from 'react-router-dom'
import React, {useContext} from 'react'
import {
  Button,
  Container,
  Divider,
  Image,
  Input,
  Message,
  Segment,
  Dimmer,
  Loader,
  Form,
  Header,
} from 'semantic-ui-react'
import client from '../apollo'
import logo from '../assets/weblogo.png'
import {RootContext} from '../RootContext'
import UserStore from '../mobx/user'
import {toast} from 'react-toastify'
import '../styles/Login.scss'

export default ({resetPass, dispatch, isRequesting, setIsRequesting, hash}) => {
  // const {setToken} = useContext(RootContext)
  return (
    <Container textAlign="center">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '20vh',
        }}
      >
        <Image src={logo} style={{maxHeight: '20vh'}} />
      </div>

      <Segment className="login-segment" padded="very">
        {isRequesting === true ? (
          <Dimmer active inverted>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
        ) : null}

        <Header as="h2" dividing>
          Password Recovery
        </Header>

        <Formik
          initialValues={{pass: ''}}
          onSubmit={async values => {
            try {
              setIsRequesting(true)
              const {data} = await resetPass({
                variables: {
                  pass: values.pass,
                  hash: hash,
                },
              })

              if (!data.resetPass) {
                toast.error('Invalid login details', {})
                return
              }

              UserStore.setToken(data.resetPass)
              // setToken(data.resetPass)
              client.resetStore()
              dispatch(push('/'))

              toast.success(
                "Your password has been reset, and you've been logged in",
                {}
              )
            } catch (e) {
              toast.error('Unable to reset password, try again later', {})
            } finally {
              setIsRequesting(false)
            }
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props

            return (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Form.Field>
                  <Input
                    id="pass"
                    type="pass"
                    value={values.pass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.pass && touched.pass
                        ? 'text-input error'
                        : 'text-input'
                    }
                    name="pass"
                    fluid
                    placeholder="New Password"
                  />

                  {errors.pass && touched.pass && (
                    <Message
                      icon="warning circle"
                      error
                      content={errors.pass}
                    />
                  )}
                </Form.Field>

                <Divider hidden as="br" />
                <Button type="submit" primary fluid>
                  Set Password
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Segment>
    </Container>
  )
}
