import {push} from 'connected-react-router'
import {withFormik} from 'formik'
import gql from 'graphql-tag'
import {compose, graphql} from 'react-apollo'
import {connect} from 'react-redux'
import {toast} from 'react-toastify'
import {withState} from 'recompose'
import AdminForm from '../components/AdminForm'

import * as Yup from 'yup'
import userStore from '../mobx/user'
import {observer} from 'mobx-react'

const AddAdminValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
})

export default compose(
  connect(),
  graphql(
    gql`
      mutation AddAdmin($input: AdminInput!) {
        addAdmin(input: $input) {
          id
          name
          email
          userType
        }
      }
    `,
    {
      name: 'addAdmin',
      options: {
        refetchQueries: ['Me', 'Admins'],
      },
    }
  ),
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      email: '',
    }),
    validationSchema: AddAdminValidationSchema,
    handleSubmit: async (input, {props: {addAdmin, dispatch}}) => {
      console.log('input', input)

      try {
        await addAdmin({
          variables: {
            input: {
              ...input,
              practiceId: Number(userStore.practiceId),
            },
          },
        })

        toast.success('Successfully created administrator.', {})
        dispatch(push(`/admins`))
      } catch (error) {
        toast.error('Failed to created administrator, please try again.', {})
      }
    },
  }),
  withState('passwordRequired', 'setPasswordRequired', true),
  withState('title', 'setTitle', 'New Administrator')
)(observer(AdminForm))
