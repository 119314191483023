import {observable, action} from 'mobx'
import {create, persist} from 'mobx-persist'
import localForage from 'localforage'

class UserStore {
  @observable hydrated = false

  @persist @observable token = ''
  @persist @observable practiceId = ''

  @action
  setToken(token) {
    this.token = token
  }

  @action
  logout() {
    this.token = ''
  }

  @action
  setPracticeId(id) {
    this.practiceId = id
  }

  @action
  clearPracticeId(id) {
    this.practiceId = false
  }
}

const hydrate = create({
  storage: localForage, // or AsyncStorage in react-native.
  // default: localStorage
  jsonify: false, // if you use AsyncStorage, here shoud be true
  // default: true
})

// create the state
const userStore = new UserStore()
hydrate('UserStore', userStore).then(() => {
  userStore.hydrated = true
  console.log('userStore has been hydrated')
})
export default userStore
