import React, {Component} from 'react'
import {Container, Grid, Icon, Header, Button} from 'semantic-ui-react'

import '../styles/PrettyError.scss'

/**
 * Pretty Error Component
 */
export default class PrettyError extends Component {
  state = {
    collapsed: true,
  }

  handleRetry = () => {
    const {onRetry} = this.props
    onRetry && onRetry()
  }

  handleReportError = () => {
    const {onReportError, error} = this.props
    onReportError && onReportError(error)
  }

  toggleCollapse = () => {
    this.setState({collapsed: !this.state.collapsed})
  }

  render() {
    const {collapsed} = this.state
    const {error} = this.props
    const {onReportError} = this.props

    return (
      <Container className={'prettyError'}>
        <Grid centered columns={2}>
          <Grid.Row>
            <Grid.Column>
              <div>
                <Header as="h2" icon textAlign="center">
                  <Icon fitted name="warning sign" />
                  <Header.Content>Oops, Something went wrong!</Header.Content>
                </Header>
              </div>

              <div>
                <Button icon labelPosition="left" onClick={this.handleRetry}>
                  <Icon name="redo" />
                  Retry
                </Button>
              </div>

              {onReportError && onReportError !== undefined && (
                <div>
                  <Button
                    icon
                    labelPosition="left"
                    onPress={this.handleReportError}
                  >
                    <Icon name="redo" />
                    Help, This keeps happening
                  </Button>
                </div>
              )}

              <div>
                <span
                  onClick={this.toggleCollapse}
                  style={{
                    color: 'rgb(153,153,153)',
                    textDecorationLine: 'underline',
                    marginVertical: '20',
                    cursor: 'pointer',
                  }}
                >
                  {collapsed ? 'Show error' : 'Hide error'}
                </span>
              </div>

              {!collapsed && (
                <div>
                  <p
                    size={12}
                    color="rgb(153,153,153)"
                    style={{marginTop: 20, marginBottom: 40}}
                  >
                    {error.message}
                  </p>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}
