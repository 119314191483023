import React from 'react'
import logo from '../assets/weblogo.png'
import {Image} from 'semantic-ui-react'

export default ({me}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 'calc(100vh - 45px)',
      flexDirection: 'column',
    }}
  >
    <Image src={logo} style={{maxHeight: '20vh'}} className="logo" />
  </div>
)
