import React, {useState} from 'react'
import {
  Container,
  Table,
  Label,
  Grid,
  Header,
  Segment,
  Divider,
  Placeholder,
  Icon,
  List,
  Image,
  TextArea,
  Button,
  Dropdown,
  Input,
  Form,
  Loader,
  Checkbox,
} from 'semantic-ui-react'
import moment from 'moment'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import PrettyError from './PrettyError'
import userStore from '../mobx/user'

export default ({
  patient,
  loading,
  id,
  error,
  refetch,
  updatePatient,
  payBillingItems,
  closePatient,
}) => {
  const [itemsToPay, setItemsToPay] = useState([])
  const [itemsUnpaid, setitemsUnpaid] = useState([])
  const [itemsBilled, setItemsBilled] = useState([])

  const [updating, setUpdating] = useState(false)
  const [itemsFilter, setItemsFilter] = useState('UnPaid')

  if (error) {
    // if (error.message.includes('doctor_no_access')) {
    //   return <ConsultationViewError consultationId={id} noAccess />
    // }
    // if (error.message.search('doctor_no_subscription')) {
    //   return <ConsultationViewError consultationId={id} />
    // }
    return <PrettyError error={error} onRetry={refetch} />
  }

  const setUnpaidActive = (name, checked) => {
    if (checked) {
      setitemsUnpaid([...itemsUnpaid, name])
    } else {
      setitemsUnpaid(itemsUnpaid.filter(e => e !== name))
    }
  }

  const setBilledActive = (name, checked) => {
    if (checked) {
      setItemsBilled([...itemsBilled, name])
    } else {
      setItemsBilled(itemsBilled.filter(e => e !== name))
    }
  }

  const updateUser = async value => {
    if (updating) return
    setUpdating(true)
    try {
      var result = await updatePatient({
        variables: {
          patientId: Number(patient.id),
          patientData: {
            status: value,
          },
        },
      })
      var goo = await refetch()
      toast.success('Patient status updated.', {})
      setUpdating(false)
    } catch (err) {
      console.log('update fail')
      console.log(JSON.stringify(err))
      setUpdating(false)
    }
  }

  const submitBilling = async () => {
    if (updating) return
    setUpdating(true)
    try {
      var result2 = await payBillingItems({
        variables: {
          patientId: Number(patient.id),
          billingItems: itemsUnpaid.map(e => Number(e)),
        },
      })
      var goo = await refetch()
      toast.success('Billing items updated.', {})
      setUpdating(false)
      setitemsUnpaid([])
    } catch (err) {
      console.log('update fail')
      console.log(JSON.stringify(err))
      setUpdating(false)
    }
  }

  const submitBilledBilling = async () => {
    if (updating) return
    setUpdating(true)
    try {
      var result2 = await payBillingItems({
        variables: {
          patientId: Number(patient.id),
          billingItems: itemsBilled.map(e => Number(e)),
        },
      })
      var goo = await refetch()
      toast.success('Billing items updated.', {})
      setUpdating(false)
      setItemsBilled([])
    } catch (err) {
      console.log('update fail')
      console.log(JSON.stringify(err))
      setUpdating(false)
    }
  }

  const closePatientt = async () => {
    if (updating) return
    setUpdating(true)
    try {
      if (
        window.confirm(
          `Are you sure you want set all billing items and patient to billed?`
        )
      ) {
        var result3 = await closePatient({
          variables: {
            patientId: Number(patient.id),
            practiceId: Number(userStore.practiceId),
          },
        })
        var goo = await refetch()
        toast.success('Patient status updated.', {})
      }
      setUpdating(false)
    } catch (err) {
      console.log('update fail')
      console.log(JSON.stringify(err))
      setUpdating(false)
    }
  }

  console.log('@ patient', patient)

  return (
    <>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Container>
                <Segment style={{marginTop: '20px', minHeight: '400px'}}>
                  <Grid
                    style={{
                      padding: '20px',
                    }}
                  >
                    {/* Patient Details */}

                    <Grid.Row>
                      <Header as="h3">Patient Details</Header>
                    </Grid.Row>

                    <Grid.Row
                      style={{
                        display: 'block',
                      }}
                    >
                      {!loading && patient && (
                        <div>
                          {patient.sticker && patient.sticker.url && (
                            <div>
                              <a target="_blank" href={patient.sticker.url}>
                                <Image
                                  // as="a"
                                  // href={patient.sticker.url}
                                  // target="_blank"
                                  src={patient.sticker.url}
                                  style={{
                                    width: '100%',
                                    height: 200,
                                    objectFit: 'contain',
                                    backgroundColor: '#eee',
                                  }}
                                />
                              </a>
                            </div>
                          )}

                          {!patient.sticker && <p>No patient sticker found</p>}

                          <Form>
                            <Form.Input
                              label="First Name"
                              placeholder="First Name"
                              value={patient.firstName}
                            />

                            <Form.Input
                              label="Last Name"
                              placeholder="Last Name"
                              value={patient.lastName}
                            />

                            {/* <Form.Input
                              label="Main Diagnosis"
                              placeholder="Main Diagnosis"
                              value={patient.diagnosis}
                            />

                            <Form.TextArea
                              label="Notes"
                              placeholder="Notes"
                              value={patient.notes}
                            /> */}
                          </Form>

                          <Divider />

                          {/* Location */}

                          <Grid.Row>
                            <Header as="h5">Location</Header>
                          </Grid.Row>

                          <Grid.Row
                            style={{
                              display: 'block',
                            }}
                          >
                            <List divided relaxed>
                              <List.Item>
                                <List.Icon
                                  name="hospital symbol"
                                  size="large"
                                  verticalAlign="middle"
                                />
                                <List.Content>
                                  <List.Header>
                                    {patient.hospital.name}
                                  </List.Header>
                                  <List.Description>
                                    {patient.hospital.formatted_address}
                                  </List.Description>
                                </List.Content>
                              </List.Item>
                            </List>
                          </Grid.Row>
                        </div>
                      )}

                      {loading && (
                        <>
                          <Placeholder>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>

                          <Placeholder>
                            <Placeholder.Line length={'very short'} />
                          </Placeholder>
                        </>
                      )}

                      {loading && (
                        <>
                          <Placeholder>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>

                          <Placeholder>
                            <Placeholder.Line length={'very short'} />
                          </Placeholder>
                        </>
                      )}
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
            </Grid.Column>
            <Grid.Column width={8} className="no-print">
              <Container>
                <Segment style={{marginTop: '20px'}}>
                  <Grid
                    style={{
                      padding: '20px',
                    }}
                  >
                    <Grid.Row>
                      <Header as="h3">Patient Settings</Header>

                      {updating && (
                        <div style={{display: 'block'}}>
                          <Loader active color={'#351ef8'} />
                        </div>
                      )}
                    </Grid.Row>
                    <Grid.Row
                      style={{
                        display: 'block',
                      }}
                    >
                      {!loading && (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              marginBottom: 16,
                              alignItems: 'baseline',
                            }}
                          >
                            <Header
                              as="h2"
                              sub
                              style={{display: 'inline-block', marginRight: 16}}
                            >
                              Edit Patient Status
                            </Header>
                            <div>
                              <Dropdown
                                item
                                simple
                                // text={
                                //   patient && patient.status
                                //     ? patient.status
                                //     : 'No Status'
                                // }
                                // disabled={selPractice ? false : true}
                                direction="right"
                                value={patient.status}
                                onChange={(event, {value}) => {
                                  updateUser(value)
                                  // UserStore.setPracticeId(value)
                                }}
                                options={[
                                  {
                                    key: 'Active',
                                    text: 'Active',
                                    value: 'Active',
                                  },
                                  {
                                    key: 'Discharged',
                                    text: 'Discharged',
                                    value: 'Discharged',
                                  },
                                  {
                                    key: 'Paid',
                                    text: 'Discharged - Billed',
                                    value: 'Paid',
                                  },
                                  {
                                    key: 'Archived',
                                    text: 'Archived',
                                    value: 'Archived',
                                  },
                                ]}
                              />
                            </div>
                          </div>
                          {/* {(patient.status === 'Active' ||
                            patient.status === 'Discharged') && (
                            <>
                              <Divider />
                              <Container fluid>
                                <Button
                                  disabled={updating}
                                  onClick={closePatientt}
                                  primary
                                >
                                  Finalise patient
                                </Button>
                                <Container fluid style={{paddingTop: 16}}>
                                  This will mark all billing items and patient
                                  status to Billed.
                                </Container>
                              </Container>
                            </>
                          )} */}
                        </>
                      )}
                      {loading && (
                        <>
                          <Placeholder>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>

                          <Placeholder>
                            <Placeholder.Line length={'very short'} />
                          </Placeholder>
                        </>
                      )}
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container style={{marginTop: 20}}>
        <Segment className="no-print">
          <Grid
            style={{
              padding: '20px',
            }}
          >
            <Grid.Row>
              <Header as="h3">Discharge Notes</Header>
            </Grid.Row>

            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}

              {!loading && patient && (
                <>
                  <Grid.Row>
                    <Header as="h5">Notes</Header>
                  </Grid.Row>
                  <Grid.Row>
                    {patient.discharge && patient.discharge.notes}
                  </Grid.Row>

                  {patient.discharge && patient.discharge.images && (
                    <>
                      <Grid.Row style={{marginTop: 8}}>
                        <Header as="h5">Attachments</Header>
                      </Grid.Row>

                      {patient.discharge.images.map(e => (
                        <Image
                          key={e.id}
                          as="a"
                          href={e.url}
                          target="_blank"
                          src={e.url}
                          size="small"
                          style={{marginRight: 16}}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>

      <Container style={{marginTop: 20}}>
        <Segment>
          <Grid
            style={{
              padding: '20px',
            }}
          >
            <Grid.Row>
              <Header as="h3">Referring Doctor</Header>
            </Grid.Row>

            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}

              {!loading && patient && (
                <Table celled padded>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Specialty</Table.HeaderCell>
                      <Table.HeaderCell>Provider Number</Table.HeaderCell>
                      {/* <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>Phone Number</Table.HeaderCell> */}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {patient.referring.map(item => {
                      const {id, name, email, specialty, provider, phone} = item
                      return (
                        <Table.Row key={id}>
                          <Table.Cell>
                            <p>{name || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{specialty || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{provider || 'N/A'}</p>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>

      <Container style={{marginTop: 20}}>
        <Segment>
          <Grid
            style={{
              padding: '20px',
            }}
          >
            {/* Billing Items */}

            <Grid.Row>
              <Header as="h3">Unpaid Items</Header>
            </Grid.Row>
            {/* {!loading && (
              <Grid.Row style={{paddingTop: 0}} className="no-print">
                <div style={{flexDirection: 'row'}}>
                  <Header
                    as="h2"
                    sub
                    style={{display: 'inline-block', marginRight: 16}}
                  >
                    Filter Billing Items
                  </Header>
                  <Dropdown
                    item
                    simple
                    // text={'UnPaid'}
                    // disabled={selPractice ? false : true}
                    direction="right"
                    value={itemsFilter}
                    onChange={(event, {value}) => {
                      setItemsFilter(value)
                      setItemsToPay([])
                    }}
                    options={[
                      {
                        key: 'UnPaid',
                        text: 'UnPaid',
                        value: 'UnPaid',
                      },
                      {
                        key: 'Billed',
                        text: 'Billed',
                        value: 'Billed',
                      },
                      {
                        key: 'Paid',
                        text: 'Paid',
                        value: 'Paid',
                      },
                    ]}
                  />
                </div>
              </Grid.Row>
            )} */}
            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}
              {!loading && patient.consultations && (
                <div>
                  <Table celled padded>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell singleLine>
                          Billing Id
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          MBS ItemNum
                        </Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          Consultation Date
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          Practitioner
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>Location</Table.HeaderCell>
                        <Table.HeaderCell>MBS Description</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {_.orderBy(
                        patient.consultations,
                        ['dateTime'],
                        ['asc']
                      ).map(session => {
                        if (
                          session.billingItems &&
                          session.billingItems.length > 0
                        ) {
                          var items = session.billingItems.filter(
                            e => e.status === 'UnPaid'
                          )
                          return items.map((bill, index) => {
                            return (
                              <Table.Row key={bill.id} style={{}}>
                                <Table.Cell textAlign="center">
                                  {bill.id}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  <Header as="h2" textAlign="center">
                                    {bill.mbsItem.ItemNum}
                                  </Header>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {bill.quantity}
                                </Table.Cell>
                                <Table.Cell>
                                  <div>
                                    {moment(session.dateTime).format(
                                      'DD/MM/YYYY - hh:mma'
                                    )}
                                  </div>
                                  <Link to={`/consultations/${session.id}`}>
                                    View consultation
                                  </Link>
                                </Table.Cell>
                                <Table.Cell>
                                  {session.practitioner.name}
                                </Table.Cell>
                                <Table.Cell>
                                  {session.hospital
                                    ? session.hospital.name
                                    : patient.hospital.name}
                                </Table.Cell>
                                <Table.Cell>
                                  {bill.mbsItem.Description}
                                </Table.Cell>
                                <Table.Cell
                                // style={{
                                //   color:
                                //     bill.status === 'Paid'
                                //       ? 'blue'
                                //       : 'inherit',
                                // }}
                                >
                                  Billed
                                  {bill.status !== 'Paid' && (
                                    <div style={{marginTop: 8}}>
                                      <Checkbox
                                        toggle
                                        //  label="Mark as Paid"
                                        name={bill.id}
                                        checked={itemsUnpaid.includes(bill.id)}
                                        onChange={(event, {name, checked}) => {
                                          setUnpaidActive(name, checked)
                                        }}
                                      />
                                    </div>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            )
                          })
                        }
                      })}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </Grid.Row>
          </Grid>
          {itemsUnpaid.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                backgroundColor: 'white',
                padding: '0px 20px',
              }}
            >
              {updating && (
                <div style={{position: 'relative', marginRight: 16}}>
                  <Loader style={{position: 'relative'}} size="small" active />
                </div>
              )}
              <Button primary onClick={submitBilling} disabled={updating}>
                Set selected items to Billed
              </Button>
            </div>
          )}
        </Segment>
      </Container>

      <Container style={{marginTop: 20}}>
        <Segment>
          <Grid
            style={{
              padding: '20px',
            }}
          >
            {/* Billing Items */}

            <Grid.Row>
              <Header as="h3">Billed Items</Header>
            </Grid.Row>
            {/* {!loading && (
              <Grid.Row style={{paddingTop: 0}} className="no-print">
                <div style={{flexDirection: 'row'}}>
                  <Header
                    as="h2"
                    sub
                    style={{display: 'inline-block', marginRight: 16}}
                  >
                    Filter Billing Items
                  </Header>
                  <Dropdown
                    item
                    simple
                    // text={'UnPaid'}
                    // disabled={selPractice ? false : true}
                    direction="right"
                    value={itemsFilter}
                    onChange={(event, {value}) => {
                      setItemsFilter(value)
                      setItemsToPay([])
                    }}
                    options={[
                      {
                        key: 'UnPaid',
                        text: 'UnPaid',
                        value: 'UnPaid',
                      },
                      {
                        key: 'Billed',
                        text: 'Billed',
                        value: 'Billed',
                      },
                      {
                        key: 'Paid',
                        text: 'Paid',
                        value: 'Paid',
                      },
                    ]}
                  />
                </div>
              </Grid.Row>
            )} */}
            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}
              {!loading && patient.consultations && (
                <div>
                  <Table celled padded>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell singleLine>
                          Billing Id
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          MBS ItemNum
                        </Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          Consultation Date
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          Practitioner
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>Location</Table.HeaderCell>
                        <Table.HeaderCell>MBS Description</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {_.orderBy(
                        patient.consultations,
                        ['dateTime'],
                        ['asc']
                      ).map(session => {
                        if (
                          session.billingItems &&
                          session.billingItems.length > 0
                        ) {
                          var items = session.billingItems.filter(
                            e => e.status === 'Billed'
                          )
                          return items.map((bill, index) => {
                            return (
                              <Table.Row key={bill.id} style={{}}>
                                <Table.Cell textAlign="center">
                                  {bill.id}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  <Header as="h2" textAlign="center">
                                    {bill.mbsItem.ItemNum}
                                  </Header>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {bill.quantity}
                                </Table.Cell>
                                <Table.Cell>
                                  <div>
                                    {moment(session.dateTime).format(
                                      'DD/MM/YYYY - hh:mma'
                                    )}
                                  </div>
                                  <Link to={`/consultations/${session.id}`}>
                                    View consultation
                                  </Link>
                                </Table.Cell>
                                <Table.Cell>
                                  {session.practitioner.name}
                                </Table.Cell>
                                <Table.Cell>
                                  {session.hospital
                                    ? session.hospital.name
                                    : patient.hospital.name}
                                </Table.Cell>
                                <Table.Cell>
                                  {bill.mbsItem.Description}
                                </Table.Cell>
                                <Table.Cell
                                // style={{
                                //   color:
                                //     bill.status === 'Paid'
                                //       ? 'blue'
                                //       : 'inherit',
                                // }}
                                >
                                  Paid
                                  {bill.status !== 'Paid' && (
                                    <div style={{marginTop: 8}}>
                                      <Checkbox
                                        toggle
                                        //  label="Mark as Paid"
                                        name={bill.id}
                                        checked={itemsBilled.includes(bill.id)}
                                        onChange={(event, {name, checked}) => {
                                          setBilledActive(name, checked)
                                        }}
                                      />
                                    </div>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            )
                          })
                        }
                      })}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </Grid.Row>
          </Grid>
          {itemsBilled.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                width: '100%',
                backgroundColor: 'white',
                padding: '0px 20px',
              }}
            >
              {updating && (
                <div style={{position: 'relative', marginRight: 16}}>
                  <Loader style={{position: 'relative'}} size="small" active />
                </div>
              )}
              <Button primary onClick={submitBilledBilling} disabled={updating}>
                Set selected items to Paid
              </Button>
            </div>
          )}
        </Segment>
      </Container>

      <Container style={{marginTop: 20}}>
        <Segment>
          <Grid
            style={{
              padding: '20px',
            }}
          >
            {/* Billing Items */}

            <Grid.Row>
              <Header as="h3">Paid Items</Header>
            </Grid.Row>
            {/* {!loading && (
              <Grid.Row style={{paddingTop: 0}} className="no-print">
                <div style={{flexDirection: 'row'}}>
                  <Header
                    as="h2"
                    sub
                    style={{display: 'inline-block', marginRight: 16}}
                  >
                    Filter Billing Items
                  </Header>
                  <Dropdown
                    item
                    simple
                    // text={'UnPaid'}
                    // disabled={selPractice ? false : true}
                    direction="right"
                    value={itemsFilter}
                    onChange={(event, {value}) => {
                      setItemsFilter(value)
                      setItemsToPay([])
                    }}
                    options={[
                      {
                        key: 'UnPaid',
                        text: 'UnPaid',
                        value: 'UnPaid',
                      },
                      {
                        key: 'Billed',
                        text: 'Billed',
                        value: 'Billed',
                      },
                      {
                        key: 'Paid',
                        text: 'Paid',
                        value: 'Paid',
                      },
                    ]}
                  />
                </div>
              </Grid.Row>
            )} */}
            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}
              {!loading && patient.consultations && (
                <div>
                  <Table celled padded>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell singleLine>
                          Billing Id
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          MBS ItemNum
                        </Table.HeaderCell>
                        <Table.HeaderCell>Quantity</Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          Consultation Date
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          Practitioner
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>Location</Table.HeaderCell>
                        <Table.HeaderCell>MBS Description</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {_.orderBy(
                        patient.consultations,
                        ['dateTime'],
                        ['asc']
                      ).map(session => {
                        if (
                          session.billingItems &&
                          session.billingItems.length > 0
                        ) {
                          var items = session.billingItems.filter(
                            e => e.status === 'Paid'
                          )
                          return items.map((bill, index) => {
                            return (
                              <Table.Row key={bill.id} style={{}}>
                                <Table.Cell textAlign="center">
                                  {bill.id}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  <Header as="h2" textAlign="center">
                                    {bill.mbsItem.ItemNum}
                                  </Header>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {bill.quantity}
                                </Table.Cell>
                                <Table.Cell>
                                  <div>
                                    {moment(session.dateTime).format(
                                      'DD/MM/YYYY - hh:mma'
                                    )}
                                  </div>
                                  <Link to={`/consultations/${session.id}`}>
                                    View consultation
                                  </Link>
                                </Table.Cell>
                                <Table.Cell>
                                  {session.practitioner.name}
                                </Table.Cell>
                                <Table.Cell>
                                  {session.hospital
                                    ? session.hospital.name
                                    : patient.hospital.name}
                                </Table.Cell>
                                <Table.Cell>
                                  {bill.mbsItem.Description}
                                </Table.Cell>
                                <Table.Cell
                                // style={{
                                //   color:
                                //     bill.status === 'Paid'
                                //       ? 'blue'
                                //       : 'inherit',
                                // }}
                                >
                                  {/* {bill.status}
                                  {bill.status !== 'Paid' && (
                                    <div style={{marginTop: 8}}>
                                      <Checkbox
                                        toggle
                                        //  label="Mark as Paid"
                                        name={bill.id}
                                        checked={itemsToPay.includes(bill.id)}
                                        onChange={(event, {name, checked}) => {
                                          setActive(name, checked)
                                        }}
                                      />
                                    </div>
                                  )} */}
                                </Table.Cell>
                              </Table.Row>
                            )
                          })
                        }
                      })}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>

      <Container style={{marginTop: 20}}>
        <Segment>
          <Grid
            style={{
              padding: '20px',
            }}
          >
            {/* Billing Items */}

            <Grid.Row>
              <Header as="h3">Billing History</Header>
            </Grid.Row>
            {/* {!loading && (
              <Grid.Row style={{paddingTop: 0}} className="no-print">
                <div style={{flexDirection: 'row'}}>
                  <Header
                    as="h2"
                    sub
                    style={{display: 'inline-block', marginRight: 16}}
                  >
                    Filter Billing Items
                  </Header>
                  <Dropdown
                    item
                    simple
                    // text={'UnPaid'}
                    // disabled={selPractice ? false : true}
                    direction="right"
                    value={itemsFilter}
                    onChange={(event, {value}) => {
                      setItemsFilter(value)
                      setItemsToPay([])
                    }}
                    options={[
                      {
                        key: 'UnPaid',
                        text: 'UnPaid',
                        value: 'UnPaid',
                      },
                      {
                        key: 'Billed',
                        text: 'Billed',
                        value: 'Billed',
                      },
                      {
                        key: 'Paid',
                        text: 'Paid',
                        value: 'Paid',
                      },
                    ]}
                  />
                </div>
              </Grid.Row>
            )} */}
            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}
              {!loading && patient.consultations && (
                <div>
                  <Table celled padded>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell singleLine>
                          Billing Id
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          MBS ItemNum
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          Actioned By
                        </Table.HeaderCell>
                        <Table.HeaderCell singleLine>
                          Action Date
                        </Table.HeaderCell>
                        <Table.HeaderCell>From Status</Table.HeaderCell>
                        <Table.HeaderCell>To Status</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {_.orderBy(
                        patient.billingHistory,
                        ['billingId', 'createdAt'],
                        ['asc', 'asc']
                      ).map(historyItem => (
                        <Table.Row key={historyItem.id} style={{}}>
                          <Table.Cell textAlign="center">
                            {historyItem.billingId}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {historyItem.billingItem.mbsItem.ItemNum}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {historyItem.user.name}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <div>
                              {moment(historyItem.createdAt).format(
                                'DD/MM/YYYY - hh:mma'
                              )}
                            </div>
                          </Table.Cell>

                          <Table.Cell>{historyItem.from}</Table.Cell>
                          <Table.Cell>{historyItem.to}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>

      <div style={{height: 100}} />

      {/* {itemsToPay.length > 0 && (
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            bottom: 0,
            width: '100%',
            backgroundColor: 'white',
            padding: 20,
            borderTop: '1px solid',
            borderTopColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 100,
          }}
        >
          {updating && (
            <div style={{position: 'relative', marginRight: 16}}>
              <Loader style={{position: 'relative'}} size="small" active />
            </div>
          )}
          <Button primary onClick={submitBilling} disabled={updating}>
            Set selected items to {itemsFilter === 'UnPaid' ? 'Billed' : 'Paid'}
          </Button>
        </div>
      )} */}
    </>
  )
}
