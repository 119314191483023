import React from 'react'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import {Link} from 'react-router-dom'
import {Button, Container, Icon, Dimmer, Loader, Label} from 'semantic-ui-react'

export default ({me, loading, history}) => (
  <>
    {loading === true ? (
      <Dimmer active inverted>
        <Loader size="medium">Loading</Loader>
      </Dimmer>
    ) : null}

    <Container fluid style={{padding: 20}}>
      <Button icon primary labelPosition="left" as={Link} to="/admins/invite">
        <Icon name="add circle" />
        Invite Administrator
      </Button>
    </Container>
    <ReactTable
      data={me && me.practice && me.practice.admins ? me.practice.admins : []}
      filterable
      className="-striped -highlight"
      defaultFilterMethod={(filter, row) =>
        String(row[filter.id]) === filter.value
      }
      getTrProps={(state, rowInfo, column, instance) => ({
        onClick: e => {
          history.push(`/admins/${rowInfo.row._original.id}`)
        },
      })}
      columns={[
        {
          Header: 'Name',
          accessor: 'name',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {keys: ['name']}),
          filterAll: true,
        },
        {
          Header: 'Account Enabled',
          accessor: 'isActive',
          filterable: false,
          sortable: false,
          Cell: ({original, value}) => {
            return (
              <Label className={value ? 'primary' : 'red'} basic size={'mini'}>
                {value ? 'Enabled' : 'Disabled'}
              </Label>
            )
          },
        },
        {
          Header: 'Email Status',
          accessor: 'emailConfirmed',
          filterable: false,
          sortable: false,
          Cell: ({original, value}) => {
            return (
              <Label className={value ? 'primary' : 'red'} basic size={'mini'}>
                {value ? 'Confirmed' : 'Unconfirmed'}
              </Label>
            )
          },
        },
        {
          Header: 'Email',
          accessor: 'email',
          filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, {keys: ['email']}),
          filterAll: true,
        },
      ]}
    />
  </>
)
