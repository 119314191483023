import gql from 'graphql-tag'
import {compose, graphql} from 'react-apollo'
import {connect} from 'react-redux'
import Consultation from '../components/Consultation'

export default compose(
  connect(),
  graphql(
    gql`
      query Consultation($id: ID!) {
        consultation(id: $id) {
          id
          dateTime
          length {
            seconds
            minutes
            hours
          }
          patient {
            id
            firstName
            lastName
            diagnosis
            notes
            ward
            bed
            referring {
              id
              name
              email
              specialty
              provider
              phone
            }
            otherSpecialists {
              id
              name
              email
              specialty
              provider
              phone
            }
            hospital {
              id
              name
              formatted_address
            }
          }
          practitioner {
            id
            name
            specialty {
              id
              name
            }
          }
          billingItems {
            id
            quantity
            status
            mbsItem {
              ItemNum
              Description
            }
          }
          sticker {
            id
            url
          }
          createdAt
        }
      }
    `,
    {
      options: ({id}) => ({
        variables: {id},
        fetchPolicy: 'cache-and-network',
      }),
      props: ({data: {consultation, loading, error, refetch} = {}}) => ({
        error,
        refetch,
        consultation,
        loading,
      }),
    }
  )
)(Consultation)
