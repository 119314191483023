import React, {useState} from 'react'
import ReactTable from 'react-table'
import matchSorter from 'match-sorter'
import {Container, Segment, List, Header, Icon} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'

// export default ({consultations, loading, fetchMore, networkStatus}) => {

@observer
export default class Consultations extends React.Component {
  render() {
    const {
      history,
      patients,
      filter,
      setFilter,

      unpaidPractitionersLoading,
      unpaidPractitioners,

      loading = true,
    } = this.props
    return (
      <>
        {!unpaidPractitionersLoading && unpaidPractitioners.length > 0 && (
          <Container fluid style={{padding: 20}}>
            <Segment>
              <Header as="h3">Unpaid Practitioners</Header>

              <List divided relaxed>
                {unpaidPractitioners.map(practitioner => {
                  return (
                    <List.Item key={`practitioner-${practitioner.id}`}>
                      <List.Icon
                        name="user"
                        size="large"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header
                          as={Link}
                          to={`/doctors/${practitioner.id}`}
                        >
                          {practitioner.name}
                        </List.Header>
                        <List.Description>
                          {practitioner.name} has unpaid consultations{' '}
                          <Link to={`/doctors/${practitioner.id}`}>
                            Subscribe Now
                          </Link>{' '}
                          to access {practitioner.name}'s consultations
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  )
                })}
              </List>
            </Segment>
          </Container>
        )}
        <ReactTable
          showPagination={true}
          data={patients ? patients : []}
          //   pageSize={first}
          //   showPageSizeOptions={true}
          //   pageSizeOptions={[10, 20, 30, 50, 100]}
          //   defaultPageSize={30}
          //   pages={
          //     consultations && consultations.pageInfo
          //       ? consultations.pageInfo.pages
          //       : 0
          //   }
          loading={loading}
          loadingText={'Loading...'}
          filterable
          //   manual
          className="-striped -highlight"
          getTrProps={(state, rowInfo, column, instance) => ({
            onClick: e => {
              console.log(rowInfo.row._original.id)
              history.push(`/patients/${rowInfo.row._original.id}`)
            },
            // onClick: e => {
            //   history.push(`/patients/${rowInfo.row._original.id}`)
            // },
          })}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          //   onPageSizeChange={(pageSize, pageIndex) => {
          //     setFirst(pageSize)
          //   }}
          onFilteredChange={(filtered, column) => {
            var result = {}
            for (var i = 0; i < filtered.length; i++) {
              result[filtered[i].id] = filtered[i].value
            }
            // setFilter(result)
          }}
          onSortedChange={(newSorted, column, shiftKey) => {}}
          defaultSortMethod={(a, b, desc) => {
            // force null and undefined to the bottom
            a = a === null || a === undefined ? '' : a
            b = b === null || b === undefined ? '' : b
            // force any string values to lowercase
            a = typeof a === 'string' ? a.toLowerCase() : a
            b = typeof b === 'string' ? b.toLowerCase() : b
            // Return either 1 or -1 to indicate a sort priority
            if (a > b) {
              return 1
            }
            if (a < b) {
              return -1
            }
            // returning 0, undefined or any falsey value will use subsequent sorts or
            // the index as a tiebreaker
            return 0
          }}
          columns={[
            {
              id: 'lastName',
              Header: 'Last Name',
              accessor: 'lastName',
              filterAll: true,
              Cell: ({original, value}) => value,
              //   filterable: false,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['lastName'],
                }),
            },
            {
              id: 'firstName',
              Header: 'First Name',
              accessor: 'firstName',
              filterAll: true,
              Cell: ({original, value}) => {
                return value
              },
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['firstName'],
                }),
            },
            {
              id: 'dateOfBirth',
              Header: 'DOB',
              accessor: 'dateOfBirth',
              filterAll: true,
              Cell: ({original, value}) => {
                return value
              },
              filterable: false,
            },
            {
              id: 'doctor',
              Header: 'Doctor',
              accessor: 'practitioner.name',
              filterAll: true,
              Cell: ({original, value}) => {
                return value
              },
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['doctor'],
                }),
            },
            {
              id: 'hospital',
              Header: 'Hospital',
              accessor: 'hospital.name',
              filterAll: true,
              Cell: ({original, value}) => {
                return value
              },
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ['hospital'],
                }),
            },
            {
              id: 'status',
              Header: 'Status',
              accessor: 'status',
              filterAll: true,
              Cell: ({original, value}) => {
                return value
              },
              Filter: ({onChange}) => (
                <select
                  onChange={event => {
                    // console.log(event.target.value)
                    setFilter(event.target.value)
                  }}
                  style={{width: '100%'}}
                  value={filter ? filter : 'Discharged'}
                >
                  <option key="Active" value="Active">
                    Active
                  </option>
                  <option key="Discharged" value="Discharged">
                    Discharged
                  </option>
                  <option key="Paid" value="Paid">
                    Discharged - Billed
                  </option>
                  <option key="Archived" value="Archived">
                    Archived
                  </option>
                </select>
              ),
            },
          ]}
        />
      </>
    )
  }
}
