import React from 'react'
import {
  Container,
  Header,
  Segment,
  Form,
  Checkbox,
  Dimmer,
  Loader,
  Button,
} from 'semantic-ui-react'
// import DoctorSubscription from '../containers/DoctorSubscription'
// import PrettyError from './PrettyError'
// import userStore from '../mobx/user'

export default ({
  // title,
  loading,
  values,
  // errors,
  // error,
  // refetch,
  // me,
  // toggleActive,
  // togglingActive,
  // setToggling,
  handleChange,
  handleSubmit,
  errors,
}) => {
  return (
    <Container>
      <Segment basic>
        {loading && (
          <Dimmer active inverted>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
        )}

        <Header as="h2">Invite Doctor</Header>
        <Form loading={loading} autoComplete="false" onSubmit={handleSubmit}>
          <Form.Field>
            <label>Name</label>
            <Form.Input
              name="name"
              value={values.name}
              required={true}
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <label>Email</label>
            <Form.Input
              name="email"
              value={values.email}
              required={true}
              onChange={handleChange}
              type={'email'}
            />
          </Form.Field>

          <Form.Button
            style={{
              marginTop: '20px',
            }}
            primary
            type="submit"
            disabled={!values.name || !values.email}
          >
            Invite Doctor
          </Form.Button>
        </Form>
      </Segment>
    </Container>
  )
}
