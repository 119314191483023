import React, {useContext} from 'react'
import {Redirect} from 'react-router-dom'
import decode from 'jwt-decode'
import UserStore from './mobx/user'

export default ({render = () => {}, children, location, ...routeProps}) => {
  const token = UserStore.token

  if (!token) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location,
          },
        }}
      />
    )
  }

  try {
    const decoded = decode(token)
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: location,
            },
          }}
        />
      )
    }
    return <>{children}</>
  } catch (err) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location,
          },
        }}
      />
    )
  }
}
