import gql from 'graphql-tag'
import {compose, graphql} from 'react-apollo'
import {connect} from 'react-redux'
import Doctor from '../components/DoctorForm'
import {withState} from 'recompose'
import userStore from '../mobx/user'

// Create updateDoctor Status, changes isActive
export default compose(
  connect(),
  graphql(
    gql`
      mutation ToggleActive($practiceId: ID!, $userId: ID!) {
        toggleActive(practiceId: $practiceId, userId: $userId) {
          id
          isActive(practiceId: $practiceId)
        }
      }
    `,
    {
      name: 'toggleActive',
      options: {
        refetchQueries: ['Doctors', 'Doctor'],
      },
    }
  ),
  graphql(
    gql`
      query Doctor($practiceId: ID!, $id: ID!) {
        me {
          id
          practice(practiceId: $practiceId) {
            id
            doctor(id: $id) {
              id
              name
              ahpra
              isActive(practiceId: $practiceId)
              specialty {
                id
                name
              }
              # Change to membership
              membership(practiceId: $practiceId) {
                isActive
                created
                stripeSubscriptionStatus
                current_period_end
                cancel_at
                cancel_at_period_end
              }
              email
              createdAt
            }
          }
        }
      }
    `,
    {
      options: ({id}) => ({
        variables: {id, practiceId: userStore.practiceId},
      }),
      props: ({data: {me, loading, error, refetch} = {}}) => ({
        me,
        loading,
        error,
        refetch,
      }),
    }
  ),
  withState('togglingActive', 'setToggling', false),
  withState('title', 'setTitle', 'Manage Doctor')
)(Doctor)
