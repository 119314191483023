import gql from 'graphql-tag'
import {compose, graphql} from 'react-apollo'
import {connect} from 'react-redux'
import DoctorSubscription from '../components/DoctorSubscription'

export default compose(
  connect(),
  graphql(
    gql`
      mutation updateCreateSubscription(
        $input: SubscriptionInput!
        $practiceId: ID!
      ) {
        updateCreateSubscription(input: $input, practiceId: $practiceId) {
          id
          name
          email
          ahpra
          userType
          membership(practiceId: $practiceId) {
            isActive
            created
            stripeSubscriptionStatus
            current_period_end
            cancel_at
            cancel_at_period_end
          }
        }
      }
    `,
    {
      name: 'updateCreateSubscription',
      options: {
        refetchQueries: ['Doctor'],
      },
    }
  ),
  graphql(
    gql`
      mutation cancelSubscription(
        $input: SubscriptionInput!
        $practiceId: ID!
      ) {
        cancelSubscription(input: $input, practiceId: $practiceId) {
          id
          name
          email
          ahpra
          userType
          membership(practiceId: $practiceId) {
            isActive
            created
            stripeSubscriptionStatus
            current_period_end
            cancel_at
            cancel_at_period_end
          }
        }
      }
    `,
    {
      name: 'cancelSubscription',
      options: {
        refetchQueries: ['Doctor'],
      },
    }
  ),
  graphql(
    gql`
      mutation resumeSubscription(
        $input: SubscriptionInput!
        $practiceId: ID!
      ) {
        resumeSubscription(input: $input, practiceId: $practiceId) {
          id
          name
          email
          ahpra
          userType
          membership(practiceId: $practiceId) {
            isActive
            created
            stripeSubscriptionStatus
            current_period_end
            cancel_at
            cancel_at_period_end
          }
        }
      }
    `,
    {
      name: 'resumeSubscription',
      options: {
        refetchQueries: ['Doctor'],
      },
    }
  )
)(DoctorSubscription)
