import {graphql, compose} from 'react-apollo'
import Patients from '../components/Patients'
import {connect} from 'react-redux'
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'
import {withState} from 'recompose'
import {observer, inject} from 'mobx-react'
import userStore from '../mobx/user'

export default compose(
  connect(),
  withState('first', 'setFirst', 30),
  withState('after', 'setAfter', 0),
  withState('filter', 'setFilter', 'Discharged'),
  withState('sort', 'setSort', {createdAt: true}),
  graphql(
    gql`
      query Doctors($practiceId: ID!, $filter: PatientStatus!) {
        me {
          practice(practiceId: $practiceId) {
            id
            patients(status: $filter) {
              id
              firstName
              lastName
              practitioner {
                id
                name
              }
              hospital {
                id
                name
              }
              ward
              bed
              dateOfBirth
              status
            }
          }
        }
      }
    `,
    {
      options: ({filter}) => ({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
          practiceId: userStore.practiceId,
          filter,
        },
      }),
      props: ({error, networkStatus, data: {me = {}, loading}}) => {
        return {
          loading,
          patients:
            me && me.practice && me.practice.patients
              ? me.practice.patients
              : [],
        }
      },
    }
  ),
  graphql(
    gql`
      query unpaidPractitioners($practiceId: ID!) {
        unpaidPractitioners(practiceId: $practiceId) {
          id
          name
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
          practiceId: userStore.practiceId,
        },
      }),
      props: ({
        // error,
        // networkStatus,
        data: {unpaidPractitioners = [], loading},
      }) => {
        return {
          unpaidPractitionersLoading: loading,
          unpaidPractitioners,
        }
      },
    }
  ),
  withApollo
)(inject('userStore')(observer(Patients)))
