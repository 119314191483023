import React, {Component} from 'react'
import {X} from 'react-feather'
import styled from 'styled-components'

const Styles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;

  .row {
    display: flex;
  }

  .modal-overlay-catch-pointer {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(130, 130, 130, 0.35);
    z-index: 101;
    animation: fadein 0.2s;
  }

  .modal-body {
    min-width: 500px;
    position: absolute;
    background-color: white;
    padding: 60px;
    z-index: 102;
    border-radius: 6px;
    box-shadow: 0 5px 52px -4px rgba(0, 0, 0, 0.15);
    animation: fadein 0.2s, slidedown 0.2s;
  }

  .modal-close {
    display: flex;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .modal-close-label {
    padding-right: 8px;
  }

  .modal-close-icon {
    cursor: pointer;
    display: flex;
    height: 27px;
    width: 27px;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: scale(0.8);
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slidedown {
    from {
      transform: translateY(-30px);
    }
    to {
      transform: translateY(0);
    }
  }
`

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const {
      children,
      onClose,
      show = false,
      scrollable = false,
      canClose = true,
    } = this.props
    // console.log(show)
    if (!show) return <div></div>
    return (
      <Styles>
        <div className="modal-overlay-catch-pointer " onClick={onClose}></div>
        <div className="modal-overlay" onClick={onClose}></div>
        <div className={`modal-body ${scrollable ? 'scrollable-modal' : ''}`}>
          {canClose && (
            <div className="row">
              <div className="modal-close" onClick={onClose}>
                <div className="modal-close-label" style={{color: '#aaa'}}>
                  Close
                </div>
                <div className="modal-close-icon">
                  <X style={{color: '#aaa', width: 15}} />
                </div>
              </div>
            </div>
          )}
          {children}
        </div>
      </Styles>
    )
  }
}

export default Modal
