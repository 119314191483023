import {graphql, compose} from 'react-apollo'
import {connect} from 'react-redux'
import {withState} from 'recompose'
import gql from 'graphql-tag'
import Login from '../components/Login'

export default compose(
  connect(),
  withState('isLoggingIn', 'setisLoggingIn', false),
  graphql(
    gql`
      query Me {
        me {
          id
        }
      }
    `,
    {
      props: ({loading, data: {me} = {}}) => ({me, loading}),
    }
  ),
  graphql(
    gql`
      mutation Login($email: String!, $password: String!) {
        loginAdmin(email: $email, password: $password)
      }
    `,
    {name: 'login'}
  )
)(Login)
