import {graphql, compose} from 'react-apollo'
import ConsultationsExport from '../components/ConsultationsExport'
import {connect} from 'react-redux'
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'
import {withState} from 'recompose'
import {observer, inject} from 'mobx-react'
import userStore from '../mobx/user'

var startingDate = new Date()
startingDate.setHours(0)
startingDate.setMonth(startingDate.getMonth() - 1)
var endingDate = new Date()
endingDate.setHours(23)
endingDate.setMinutes(59)

const filtyBoy = {
  start: startingDate,
  end: endingDate,
  doctorId: null,
  patientId: null,
}

export default compose(
  connect(),
  withState('first', 'setFirst', 30),
  withState('after', 'setAfter', 0),
  withState('filter', 'setFilter', filtyBoy),
  withState('sort', 'setSort', {createdAt: true}),
  graphql(
    gql`
      query Doctors($practiceId: ID!) {
        me {
          practice(practiceId: $practiceId) {
            id
            doctors {
              id
              name
            }
            patients {
              id
              firstName
              lastName
              dateOfBirth
            }
          }
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
          practiceId: userStore.practiceId,
        },
      }),
      props: ({error, networkStatus, data: {me = {}, loading}}) => {
        return {
          loading,
          doctors:
            me && me.practice && me.practice.doctors ? me.practice.doctors : [],
          patients:
            me && me.practice && me.practice.patients
              ? me.practice.patients
              : [],
        }
      },
    }
  ),
  graphql(
    gql`
      query unpaidPractitioners($practiceId: ID!) {
        unpaidPractitioners(practiceId: $practiceId) {
          id
          name
        }
      }
    `,
    {
      options: () => ({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
          practiceId: userStore.practiceId,
        },
      }),
      props: ({
        // error,
        // networkStatus,
        data: {unpaidPractitioners = [], loading},
      }) => {
        return {
          unpaidPractitionersLoading: loading,
          unpaidPractitioners,
        }
      },
    }
  ),
  graphql(
    gql`
      query Consultations($practiceId: ID!, $filter: ConsultationExportInput) {
        exportConsultations(filter: $filter, practiceId: $practiceId) {
          id
          length {
            seconds
            minutes
            hours
          }
          diagnosis
          patient {
            id
            firstName
            dateOfBirth
            lastName
            diagnosis
            hospital {
              id
              name
            }
            referring {
              id
              name
            }
          }
          hospital {
            id
            name
          }
          practitioner {
            id
            name
            specialty {
              id
              name
            }
          }
          createdAt
          dateTime
          billingItems {
            id
            quantity
            status
            mbsItem {
              ItemNum
              Description
            }
          }
        }
      }
    `,
    {
      options: ({first, after, filter, sort}) => ({
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: {
          first,
          after,
          filter,
          sort,
          practiceId: userStore.practiceId,
        },
      }),
      props: ({
        error,
        networkStatus,
        data: {exportConsultations, fetchMore, refetch, loading} = {},
      }) => ({
        exportConsultations,
        loading,
        error,
        fetchMore,
        refetch,
        networkStatus,
      }),
    }
  ),
  withApollo
)(inject('userStore')(observer(ConsultationsExport)))
