import React, {useEffect, useState} from 'react'
import UserStore from './mobx/user'

export const RootContext = React.createContext()
export default ({children}) => {
  const prevToken = window.localStorage.getItem('token') || null
  const prevPracticeId = window.localStorage.getItem('practiceId') || null

  const [token, setToken] = useState(prevToken)
  const [practiceId, setPracticeId] = useState(prevPracticeId)

  useEffect(() => {
    if (token) {
      window.localStorage.setItem('token', token)
      UserStore.setToken(token)
    }
  }, [token])

  useEffect(() => {
    if (practiceId) {
      window.localStorage.setItem('practiceId', practiceId)
      UserStore.setPracticeId(practiceId)
    }
  }, [practiceId])

  return (
    <RootContext.Provider
      value={{
        token,
        setToken,
        practiceId,
        setPracticeId,
      }}
    >
      {children}
    </RootContext.Provider>
  )
}
