import {graphql, compose} from 'react-apollo'
import {connect} from 'react-redux'
import {withState} from 'recompose'
import gql from 'graphql-tag'
import Recover from '../components/RecoverAccount'

export default compose(
  connect(),
  withState('isRequesting', 'setIsRequesting', false),
  graphql(
    gql`
      query Me {
        me {
          id
        }
      }
    `,
    {
      props: ({loading, data: {me} = {}}) => ({me, loading}),
    }
  ),
  graphql(
    gql`
      mutation ResetPass($hash: String!, $pass: String!) {
        resetPass(hash: $hash, pass: $pass)
      }
    `,
    {
      name: 'resetPass',
      options: ({hash}) => ({
        variables: {hash},
      }),
    }
  )
)(Recover)
