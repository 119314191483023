import {push} from 'connected-react-router'
import {NavLink} from 'react-router-dom'
import React, {Component} from 'react'

import {
  Button,
  Container,
  Divider,
  Image,
  Input,
  Message,
  Segment,
  Dimmer,
  Loader,
  Form,
  Header,
} from 'semantic-ui-react'

import logo from '../assets/weblogo.png'
import {toast} from 'react-toastify'
import {parseError} from '../utils'
// import '../styles/Login.scss'

export default class AcceptInvite extends Component {
  state = {
    submitting: false,
    error: '',
    password: '',
    confirmPassword: '',
  }

  async componentDidMount() {}

  handleChange = (key, e) => {
    this.setState({[key]: e.target.value})
  }

  acceptInvite = async () => {
    const {acceptInvite, hash} = this.props
    const {password, confirmPassword} = this.state

    this.setState({submitting: true})

    try {
      let result = await acceptInvite({
        variables: {hash, password, confirmPassword},
      })

      console.log('result', result)

      if (result.data.acceptInvite) {
        toast.success(
          'Your account was activated successfully, You may now login',
          {}
        )
        this.props.dispatch(push(`/login`))
      }

      // this.setState({result})
    } catch (err) {
      // console.log('Err', JSON.stringify(err, null, 2))
      toast.error(
        // 'Failed to send invite to practitioner, please try again.',
        parseError(err),
        {}
      )
      this.setState({error: parseError(err)})
    }

    this.setState({submitting: false})
  }

  render() {
    const {submitting, error, password, confirmPassword} = this.state
    return (
      <Container textAlign="center">
        <Segment padded="very">
          <Header as="h2">Accept Invite</Header>
          <Form
            loading={submitting}
            autoComplete="false"
            onSubmit={this.acceptInvite}
          >
            <Form.Field>
              <label>Password</label>
              <Form.Input
                name="password"
                value={password}
                required={true}
                onChange={this.handleChange.bind(null, 'password')}
                type={'password'}
              />
            </Form.Field>

            <Form.Field>
              <label>Confirm Password</label>
              <Form.Input
                name="confirmPassword"
                value={confirmPassword}
                required={true}
                onChange={this.handleChange.bind(null, 'confirmPassword')}
                type={'password'}
              />
            </Form.Field>

            <Form.Button
              style={{
                marginTop: '20px',
              }}
              primary
              type="submit"
              disabled={!password || !confirmPassword}
            >
              Accept Invite
            </Form.Button>
          </Form>

          {/*
          {submitting === true ? (
            <Dimmer active inverted>
              <Loader size="medium">Loading</Loader>
            </Dimmer>
          ) : null}

          {!!result && !error && <Header as="h2">Account Activated</Header>}

          {!!error && <Header as="h2">{error}</Header>}
          */}
        </Segment>
      </Container>
    )
  }
}
