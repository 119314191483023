export function parseError(err) {
  if (
    !err ||
    !err.graphQLErrors ||
    !err.graphQLErrors.length ||
    err.graphQLErrors.length === 0
  ) {
    return 'Oops, Something went wrong'
  }
  return err.graphQLErrors[0].message
}

export function isPracticeOwner(practiceId, me) {
  var gweg = false
  me.practices.forEach(p => {
    if (p.id === practiceId && p.owner && p.owner.id === me.id) {
      gweg = true
    }
  })
  return gweg
}
