import {push} from 'connected-react-router'
import {Formik} from 'formik'
import {NavLink} from 'react-router-dom'
import React, {useContext} from 'react'
import {
  Button,
  Container,
  Divider,
  Image,
  Input,
  Message,
  Segment,
  Dimmer,
  Loader,
  Header,
  Form,
} from 'semantic-ui-react'
import client from '../apollo'
import logo from '../assets/weblogo.png'
import {toast} from 'react-toastify'
import {parseError} from '../utils'
import UserStore from '../mobx/user'
import '../styles/Login.scss'

export default ({login, dispatch, isLoggingIn, setisLoggingIn, location}) => {
  return (
    <Container textAlign="center">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '300px',
        }}
      >
        <Image src={logo} style={{maxHeight: '150px'}} />
      </div>

      <Segment className="login-segment" padded="very">
        {isLoggingIn === true ? (
          <Dimmer active inverted>
            <Loader size="medium">Loading</Loader>
          </Dimmer>
        ) : null}

        <Header as="h2" dividing>
          Login
        </Header>

        <Formik
          initialValues={{email: '', password: ''}}
          onSubmit={async values => {
            try {
              setisLoggingIn(true)
              const response = await login({variables: values})
              const {data} = response

              if (!data.loginAdmin) {
                toast.error('Invalid login details', {})
                return
              }

              UserStore.setToken(data.loginAdmin)
              client.resetStore()

              if (location && location.state && location.state.from) {
                dispatch(push(location.state.from.pathname))
              } else {
                dispatch(push('/'))
              }
              toast.success("You've been logged in", {})
            } catch (e) {
              client.resetStore()

              console.log('e', JSON.stringify(e, null, 2))

              //'Unable to login, try again later'
              toast.error(parseError(e), {})
            } finally {
              setisLoggingIn(false)
            }
          }}
        >
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props

            return (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <Form.Field>
                  <Input
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.email && touched.email
                        ? 'text-input error'
                        : 'text-input'
                    }
                    name="email"
                    fluid
                    placeholder="Email"
                  />

                  {errors.email && touched.email && (
                    <Message
                      icon="warning circle"
                      error
                      content={errors.email}
                    />
                  )}
                </Form.Field>

                <Form.Field>
                  <Input
                    id="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.password && touched.password
                        ? 'text-input error'
                        : 'text-input'
                    }
                    name="password"
                    fluid
                    placeholder="Password"
                  />

                  {errors.password && touched.password && (
                    <Message
                      icon="warning circle"
                      error
                      content={errors.password}
                    />
                  )}
                </Form.Field>

                <Divider hidden as="br" />
                <Button type="submit" primary fluid>
                  Login
                </Button>
              </Form>
            )
          }}
        </Formik>
        <div style={{marginTop: 10}}>
          <NavLink to="/recover">Forgot Password?</NavLink>
        </div>

        <Divider />

        <p>
          Not a member yet? <NavLink to="/signup">Join Now</NavLink>
        </p>
      </Segment>
    </Container>
  )
}
