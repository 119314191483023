import {graphql, compose} from 'react-apollo'
import App from '../components/App'
import {connect} from 'react-redux'
import gql from 'graphql-tag'
import {withApollo} from 'react-apollo'

export default compose(
  connect(),
  graphql(
    gql`
      query Me {
        me {
          id
          name
          email
          userType
          practices(isActive: true) {
            id
            name
            owner {
              id
            }
          }
        }
      }
    `,
    {
      props: ({loading, data: {me} = {}}) => ({me, loading}),
    }
  ),
  withApollo
)(App)
