import {graphql, compose} from 'react-apollo'
import {connect} from 'react-redux'
import {withState} from 'recompose'
import gql from 'graphql-tag'
import AcceptInvite from '../components/AcceptInvite'

export default compose(
  connect(),
  graphql(
    gql`
      mutation acceptInvite(
        $hash: String!
        $password: String!
        $confirmPassword: String!
      ) {
        acceptInvite(
          hash: $hash
          password: $password
          confirmPassword: $confirmPassword
        )
      }
    `,
    {
      name: 'acceptInvite',
    }
  )
)(AcceptInvite)
