import React from 'react'
import {
  Divider,
  Header,
  Label,
  Segment,
  Button,
  Icon,
  Dimmer,
  Loader,
  Modal,
} from 'semantic-ui-react'
import {
  StripeProvider,
  CardElement,
  Elements,
  injectStripe,
} from 'react-stripe-elements'
import '../styles/DoctorSubscription.scss'
import moment from 'moment'
import {toast} from 'react-toastify'
import {inject, observer} from 'mobx-react'

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }
}

@inject('userStore')
@observer
class _CardForm extends React.Component {
  state = {modalOpen: false}
  handleOpen = () => this.setState({modalOpen: true})
  handleClose = () => this.setState({modalOpen: false})

  handleSubmit = async ev => {
    const {
      setStripeLoading,
      updateCreateSubscription,
      doctor,
      userStore,
    } = this.props

    ev.preventDefault()

    var {token, error} = await this.props.stripe.createToken()
    if (error) {
      return toast.error(error.message)
    }

    setStripeLoading(true)

    try {
      var resp = await updateCreateSubscription({
        variables: {
          input: {
            userId: doctor.id,
            token: token.id,
          },
          practiceId: userStore.practiceId,
        },
      })

      console.log('resp', resp)

      toast.success('Subscribed doctor')
    } catch (error) {
      console.log('error', error)
      toast.error(error)
    } finally {
      setStripeLoading(false)
    }
  }

  handleResumeSubscription = async ev => {
    const {setStripeLoading, resumeSubscription, doctor, userStore} = this.props
    ev.preventDefault()
    this.handleClose()

    setStripeLoading(true)
    try {
      await resumeSubscription({
        variables: {
          input: {
            userId: doctor.id,
          },
          practiceId: userStore.practiceId,
        },
      })
    } catch (error) {
      console.log('error', error)
    } finally {
      setStripeLoading(false)
    }
  }

  handleCancel = async ev => {
    const {setStripeLoading, cancelSubscription, doctor, userStore} = this.props
    ev.preventDefault()
    this.handleClose()

    setStripeLoading(true)
    try {
      await cancelSubscription({
        variables: {
          input: {
            userId: doctor.id,
          },
          practiceId: userStore.practiceId,
        },
      })
    } catch (error) {
      console.log('error', error)
    } finally {
      setStripeLoading(false)
    }
  }

  render() {
    const {fontSize, doctor} = this.props
    return (
      <form onSubmit={this.handleSubmit} className={'doctorCardDetails'}>
        <label>
          Card details
          <CardElement {...createOptions(fontSize)} />
        </label>

        <Button animated primary>
          <Button.Content visible>
            {doctor.membership.stripeSubscriptionStatus === 'ACTIVE'
              ? 'Update Subscription'
              : 'Subscribe Doctor'}
          </Button.Content>
          <Button.Content hidden>
            <Icon name="checkmark" />
          </Button.Content>
        </Button>

        {doctor.membership &&
          doctor.membership.cancel_at_period_end &&
          doctor.membership.stripeSubscriptionStatus === 'ACTIVE' && (
            <Button
              animated
              color="green"
              floated="right"
              onClick={this.handleResumeSubscription}
            >
              <Button.Content visible>Resume Subscription</Button.Content>
              <Button.Content hidden>
                <Icon name="long arrow alternate right" />
              </Button.Content>
            </Button>
          )}

        {doctor.membership &&
          !doctor.membership.cancel_at_period_end &&
          doctor.membership.stripeSubscriptionStatus === 'ACTIVE' && (
            <Modal
              trigger={
                <Button
                  animated
                  color="red"
                  floated="right"
                  onClick={ev => {
                    ev.preventDefault()
                    this.handleOpen()
                  }}
                >
                  <Button.Content visible>Cancel Subscription</Button.Content>
                  <Button.Content hidden>
                    <Icon name="close" />
                  </Button.Content>
                </Button>
              }
              open={this.state.modalOpen}
              onClose={this.handleClose}
              basic
              size="small"
            >
              <Header icon="warning sign" content="Are you sure" />
              <Modal.Content>
                <p>
                  This subscription will be set to expire at the end of the
                  billing period, after the subscription expires, you will no
                  longer have access to this doctors consultations.
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button basic color="grey" inverted onClick={this.handleClose}>
                  <Icon name="remove" /> No
                </Button>
                <Button color="red" inverted onClick={this.handleCancel}>
                  <Icon name="checkmark" /> Yes
                </Button>
              </Modal.Actions>
            </Modal>
          )}
      </form>
    )
  }
}
const CardForm = injectStripe(_CardForm)

export default class DoctorSubscription extends React.Component {
  constructor() {
    super()
    this.state = {
      stripeLoading: false,
    }
  }

  _setStripeLoading = stripeLoading => {
    this.setState({stripeLoading})
  }

  render() {
    const {
      doctor,
      updateCreateSubscription,
      cancelSubscription,
      resumeSubscription,
    } = this.props

    return (
      <>
        <Divider />
        <Segment
          padded
          style={{
            backgroundColor: '#f0f1f5',
          }}
        >
          <Dimmer active={this.state.stripeLoading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>

          <Header
            as="h3"
            sub
            style={{
              marginTop: '0px',
            }}
          >
            Subscription
            <Label
              color={
                doctor.membership.stripeSubscriptionStatus === 'ACTIVE'
                  ? 'green'
                  : 'red'
              }
              size={'tiny'}
            >
              {doctor.membership.stripeSubscriptionStatus}
            </Label>
            {doctor.membership && doctor.membership.cancel_at_period_end && (
              <Label color={'grey'} size={'tiny'}>
                Pending Cancelation
              </Label>
            )}
          </Header>

          {doctor.membership && doctor.membership.cancel_at_period_end && (
            <span
              style={{
                display: 'block',
                marginBottom: '10px',
              }}
            >
              Will be cancelled on{' '}
              {moment.unix(doctor.membership.cancel_at).format('DD/MM/YYYY')}
            </span>
          )}

          <div className={'doctorSubscription'}>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUB_KEY}>
              <Elements>
                <CardForm
                  doctor={doctor}
                  setStripeLoading={this._setStripeLoading}
                  updateCreateSubscription={updateCreateSubscription}
                  cancelSubscription={cancelSubscription}
                  resumeSubscription={resumeSubscription}
                  fontSize={'18px'}
                />
              </Elements>
            </StripeProvider>
          </div>
        </Segment>
      </>
    )
  }
}
