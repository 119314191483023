import {ConnectedRouter} from 'connected-react-router'
import React from 'react'
import {Route, Switch} from 'react-router'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AuthenticatedRoute from '../AuthenticatedRoute'
import {version} from '../constants'
import AcceptInvite from '../containers/AcceptInvite'
import ActivateAccount from '../containers/ActivateAccount'
import AddAdmin from '../containers/AddAdmin'
import AddDoctor from '../containers/AddDoctor'
import Admin from '../containers/Admin'
import Admins from '../containers/Admins'
import Consultation from '../containers/Consultation'
import Consultations from '../containers/Consultations'
import ConsultationsDelete from '../containers/ConsultationsDelete'
import ConsultationsExport from '../containers/ConsultationsExport'
import Doctor from '../containers/Doctor'
import Doctors from '../containers/Doctors'
import Index from '../containers/Index'
import Login from '../containers/Login'
import Patient from '../containers/Patient'
import Patients from '../containers/Patients'
import Recover from '../containers/Recover'
import ResetPassword from '../containers/RecoverAccount'
import '../styles/App.css'
import Navigation from './Navigation'

export default ({history, me, client, loading}) => {
  return (
    <ConnectedRouter history={history}>
      {loading && <div>loading...</div>}

      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/recover" component={Recover} />

        <Route
          exact
          path="/recover/:hash"
          render={({match}) => <ResetPassword hash={match.params.hash} />}
        />

        <Route
          exact
          path="/activate/:hash"
          render={({match}) => <ActivateAccount hash={match.params.hash} />}
        />

        <Route
          exact
          path="/accept-invite/:hash"
          render={({match}) => <AcceptInvite hash={match.params.hash} />}
        />

        <Switch>
          <AuthenticatedRoute>
            <Navigation me={me}>
              <Switch>
                <Route exact path="/" component={Index} />
                <Route exact path="/doctors" component={Doctors} />
                <Route exact path="/doctors/invite" component={AddDoctor} />
                <Route
                  exact
                  path="/doctors/:id"
                  render={({match}) => <Doctor id={match.params.id} />}
                />

                <Route exact path="/admins" component={Admins} />
                <Route exact path="/admins/invite" component={AddAdmin} />
                <Route
                  exact
                  path="/admins/:id"
                  render={({match}) => <Admin id={match.params.id} />}
                />

                <Route exact path="/patients" component={Patients} />
                <Route
                  exact
                  path="/patients/:id"
                  render={({match}) => <Patient id={match.params.id} />}
                />

                <Route exact path="/consultations" component={Consultations} />
                <Route
                  exact
                  path="/consultations/export"
                  component={ConsultationsExport}
                />
                <Route
                  exact
                  path="/consultations/delete"
                  component={ConsultationsDelete}
                />
                <Route
                  exact
                  path="/consultations/:id"
                  render={({match}) => <Consultation id={match.params.id} />}
                />

                <Route component={() => <div>404</div>} />
              </Switch>
            </Navigation>
          </AuthenticatedRoute>
        </Switch>

        <Route component={() => <div>404</div>} />
      </Switch>

      <ToastContainer
        style={{padding: 30}}
        newestOnTop={true}
        position="top-right"
      />
      <div
        style={{
          fontSize: 13,
          position: 'fixed',
          bottom: 4,
          right: 4,
          color: '#ccc',
        }}
      >
        {version}
      </div>
    </ConnectedRouter>
  )
}
