import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Image,
  Label,
  List,
  Placeholder,
  Segment,
  Table,
} from 'semantic-ui-react'
import ConsultationViewError from '../containers/ConsultationViewError'
import PrettyError from './PrettyError'

export default ({consultation, loading, id, error, refetch}) => {
  if (error) {
    if (error.message.includes('doctor_no_access')) {
      return <ConsultationViewError consultationId={id} noAccess />
    }
    if (error.message.search('doctor_no_subscription')) {
      return <ConsultationViewError consultationId={id} />
    }
    return <PrettyError error={error} onRetry={refetch} />
  }

  console.log('@ consultation', consultation)

  return (
    <>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Container>
                <Segment style={{marginTop: '20px'}}>
                  <Grid
                    style={{
                      padding: '20px',
                    }}
                  >
                    <Grid.Row>
                      <Header as="h3">Consultation Details</Header>
                    </Grid.Row>

                    <Grid.Row
                      style={{
                        display: 'block',
                      }}
                    >
                      {!loading && consultation && (
                        <div>
                          <Header as="h2" sub>
                            {consultation.patient.firstName}'s consulation with{' '}
                            {consultation.practitioner.name}{' '}
                            <Label size={'tiny'}>
                              {!!consultation.practitioner.specialty
                                ? consultation.practitioner.specialty.name
                                : ''}
                            </Label>
                          </Header>
                          <span>
                            {moment(
                              consultation.dateTime
                                ? consultation.dateTime
                                : consultation.createdAt
                            ).format('hh:mma DD/MM/YYYY')}
                          </span>
                        </div>
                      )}

                      {loading && (
                        <>
                          <Placeholder>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>

                          <Placeholder>
                            <Placeholder.Line length={'very short'} />
                          </Placeholder>
                        </>
                      )}
                    </Grid.Row>

                    <Grid.Row>
                      {!loading && consultation && (
                        <>
                          {'This consultation went for'}&nbsp;
                          <strong>
                            {`${
                              consultation.length.hours
                                ? consultation.length.hours + ' Hours'
                                : ''
                            } ${
                              consultation.length.minutes
                                ? consultation.length.minutes + ' Minutes'
                                : ''
                            }`}
                          </strong>
                        </>
                      )}
                    </Grid.Row>

                    <Divider />

                    {/* Location */}

                    <Grid.Row>
                      <Header as="h5">Location</Header>
                    </Grid.Row>

                    <Grid.Row
                      style={{
                        display: 'block',
                      }}
                    >
                      {!loading && consultation && (
                        <List divided relaxed>
                          <List.Item>
                            <List.Icon
                              name="hospital symbol"
                              size="large"
                              verticalAlign="middle"
                            />
                            <List.Content>
                              <List.Header>
                                {consultation.patient.hospital.name}
                              </List.Header>
                              <List.Description>
                                {
                                  consultation.patient.hospital
                                    .formatted_address
                                }
                              </List.Description>
                            </List.Content>
                          </List.Item>
                        </List>
                      )}

                      {loading && (
                        <>
                          <Placeholder>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>

                          <Placeholder>
                            <Placeholder.Line length={'very short'} />
                          </Placeholder>
                        </>
                      )}
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
            </Grid.Column>
            <Grid.Column width={8}>
              <Container>
                <Segment style={{marginTop: '20px', minHeight: '400px'}}>
                  <Grid
                    style={{
                      padding: '20px',
                    }}
                  >
                    {/* Patient Details */}

                    <Grid.Row>
                      <Header as="h3">Patient Details</Header>
                    </Grid.Row>

                    <Grid.Row
                      style={{
                        display: 'block',
                      }}
                    >
                      {!loading && consultation && (
                        <div>
                          {consultation.sticker && consultation.sticker.url && (
                            <a target="_blank" href={consultation.sticker.url}>
                              <Image
                                src={consultation.sticker.url}
                                style={{
                                  width: '100%',
                                  height: 200,
                                  objectFit: 'contain',
                                  backgroundColor: '#eee',
                                }}
                              />
                            </a>
                          )}

                          {!consultation.sticker && (
                            <p>No patient sticker found</p>
                          )}
                          <Link to={`/patients/${consultation.patient.id}`}>
                            Go to Patient Overview
                          </Link>
                          <Form>
                            <Form.Input
                              label="First Name"
                              placeholder="First Name"
                              value={consultation.patient.firstName}
                            />

                            <Form.Input
                              label="Last Name"
                              placeholder="Last Name"
                              value={consultation.patient.lastName}
                            />

                            <Form.Input
                              label="Main Diagnosis"
                              placeholder="Main Diagnosis"
                              value={consultation.patient.diagnosis}
                            />

                            <Form.TextArea
                              label="Notes"
                              placeholder="Notes"
                              value={consultation.patient.notes}
                            />

                            <Form.Input
                              label="Ward"
                              placeholder="Ward"
                              value={consultation.patient.ward}
                            />

                            <Form.Input
                              label="Bed"
                              placeholder="Bed"
                              value={consultation.patient.bed}
                            />
                          </Form>
                        </div>
                      )}

                      {loading && (
                        <>
                          <Placeholder>
                            <Placeholder.Header>
                              <Placeholder.Line />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>

                          <Placeholder>
                            <Placeholder.Line length={'very short'} />
                          </Placeholder>
                        </>
                      )}
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      {/* Referring Doctor */}
      <Container style={{marginTop: 20}}>
        <Segment>
          <Grid
            style={{
              padding: '20px',
            }}
          >
            {/* Billing Items */}

            <Grid.Row>
              <Header as="h3">Billing Items</Header>
            </Grid.Row>

            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}

              {!loading && consultation && (
                <Table celled padded>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell singleLine>
                        MBS ItemNum
                      </Table.HeaderCell>
                      <Table.HeaderCell>Quantity</Table.HeaderCell>
                      <Table.HeaderCell>MBS Description</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {consultation.billingItems.map((billingItem) => (
                      <Table.Row key={billingItem.id}>
                        <Table.Cell>
                          <Header as="h2" textAlign="center">
                            {billingItem.mbsItem.ItemNum}
                          </Header>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {billingItem.quantity}
                        </Table.Cell>
                        <Table.Cell>
                          {billingItem.mbsItem.Description}
                        </Table.Cell>
                        <Table.Cell>{billingItem.status}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>

      {/* Referring Doctor */}
      <Container style={{marginTop: 20}}>
        <Segment>
          <Grid
            style={{
              padding: '20px',
            }}
          >
            <Grid.Row>
              <Header as="h3">Referring Doctor</Header>
            </Grid.Row>

            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}

              {!loading && consultation && (
                <Table celled padded>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Specialty</Table.HeaderCell>
                      <Table.HeaderCell>Provider Number</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>Phone Number</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {consultation.patient.referring.map((item) => {
                      const {id, name, email, specialty, provider, phone} = item
                      return (
                        <Table.Row key={id}>
                          <Table.Cell>
                            <p>{name || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{specialty || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{provider || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{email || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{phone || 'N/A'}</p>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>

      {/* Other Specialists Involved */}
      <Container style={{marginTop: 20}}>
        <Segment>
          <Grid
            style={{
              padding: '20px',
            }}
          >
            <Grid.Row>
              <Header as="h3">Other Specialists Involved</Header>
            </Grid.Row>

            <Grid.Row
              style={{
                display: 'block',
              }}
            >
              {loading && (
                <Placeholder>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}

              {!loading && consultation && (
                <Table celled padded>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Specialty</Table.HeaderCell>
                      <Table.HeaderCell>Provider Number</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                      <Table.HeaderCell>Phone Number</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {consultation.patient.otherSpecialists.map((item) => {
                      const {id, name, email, specialty, provider, phone} = item
                      return (
                        <Table.Row key={id}>
                          <Table.Cell>
                            <p>{name || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{specialty || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{provider || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{email || 'N/A'}</p>
                          </Table.Cell>
                          <Table.Cell>
                            <p>{phone || 'N/A'}</p>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table>
              )}
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>

      <div style={{height: 100}} />
    </>
  )
}
