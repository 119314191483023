import {push} from 'connected-react-router'
import {withFormik} from 'formik'
import gql from 'graphql-tag'
import {compose, graphql} from 'react-apollo'
import {connect} from 'react-redux'
import {toast} from 'react-toastify'
import {withState} from 'recompose'
// import DoctorForm from '../components/DoctorForm'
import AddDoctor from '../components/AddDoctor'

import * as Yup from 'yup'

// import branch from 'branch-sdk'
import {observer} from 'mobx-react'
import userStore from '../mobx/user'

const InviteDoctorValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
})

export default compose(
  connect(),
  graphql(
    gql`
      mutation inviteDoctor($email: String!, $name: String!, $practiceId: ID!) {
        inviteDoctor(email: $email, name: $name, practiceId: $practiceId)
      }
    `,
    {
      name: 'inviteDoctor',
    }
  ),
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      email: '',
    }),
    validationSchema: InviteDoctorValidationSchema,
    handleSubmit: async ({email, name}, {props: {inviteDoctor, dispatch}}) => {
      console.log('@@ GOT FORMIK SUBMIT')

      console.log('userStore.practiceId', userStore.practiceId)

      try {
        let result = await inviteDoctor({
          variables: {
            email,
            name,
            practiceId: userStore.practiceId,
          },
        })

        console.log('result', result)

        toast.success('Successfully sent invite to practitioner.', {})
        dispatch(push(`/doctors`))
      } catch (error) {
        toast.error(
          'Failed to send invite to practitioner, please try again.',
          {}
        )
      }
    },
  })
)(observer(AddDoctor))
